import { array, boolean, InferType, mixed, object, string } from "yup";
import { DocumentAnswerItemSchema } from "./document";

const StepValueSchema = object({
  answer: string().optional(),
  answerFileId: string().optional(),
  answerItems: array().of(DocumentAnswerItemSchema).optional(),
});

export enum AssistantProcessStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export enum StatusMessageType {
  Error = "error",
  Info = "info",
}

//TODO: align with type in types/index
export const SuggestionSchema = object({
  applied: boolean().required(),
  value: string().nullable().defined(),
  completed: boolean().required(),
  isStatusMessage: boolean().required(),
  statusMessageType: mixed<StatusMessageType>().oneOf(
    Object.values(StatusMessageType)
  ),
  loading: boolean().required(),
  error: boolean().required(),
});

export interface Suggestion {
  applied: boolean;
  value: string | null;
  completed: boolean;
  isStatusMessage: boolean;
  statusMessageType?: StatusMessageType;
  loading: boolean;
  error: boolean;
}

//TODO: align with type in types/index
const AssistantMultiStepFormAnswerSchema = object({
  dataKeyId: string().required(),
  templateId: string().required(),
  previousAnswer: StepValueSchema.optional(),
  acceptedAnswer: StepValueSchema.optional(),
  suggestion: SuggestionSchema.default(undefined).optional(),
});

export interface AssistantMultiStepFormAnswer {
  dataKeyId: string;
  templateId: string;
  previousAnswer?: InferType<typeof StepValueSchema>;
  acceptedAnswer?: InferType<typeof StepValueSchema>;
  suggestion?: Suggestion;
}

//TODO: align with type in types/index
const AssistantMultiStepFormStepSchema = object({
  dataKeyId: string().required(),
  templateId: string().required(),
});

export interface AssistantMultiStepFormStep {
  dataKeyId: string;
  templateId: string;
}

const AssistantProcessStateSchema = object({
  changeType: string().required(),
  currentPath: string().required(),
  checkboxes: array().of(string().required()).required(),
  description: string().default("").optional(),
  dataKeysToUpdate: array().of(AssistantMultiStepFormStepSchema).required(),
  changes: array().of(AssistantMultiStepFormAnswerSchema).required(),
});

type AssistantProcessState = {
  changeType: string;
  currentPath: string;
  checkboxes: string[];
  description: string;
  dataKeysToUpdate: AssistantMultiStepFormStep[];
  changes: AssistantMultiStepFormAnswer[];
};

export const AssistantProcessSchema = object({
  id: string().required(),
  state: AssistantProcessStateSchema.required(),
  deviceId: string().required(),
  organizationId: string().required(),
  createdAt: string().required(),
  updatedAt: string().required(),
  createdBy: string().required(),
  status: string().oneOf(Object.values(AssistantProcessStatus)).required(),
});

export interface AssistantProcess {
  id: string;
  state: AssistantProcessState;
  deviceId: string;
  organizationId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  status: AssistantProcessStatus;
}

export type PatchAssistantProcessBody = {
  status?: AssistantProcessStatus;
  state?: AssistantProcessState;
};

export const validateAssistantProcess = (
  data: unknown
): Promise<AssistantProcess> => {
  return AssistantProcessSchema.validate(data) as Promise<AssistantProcess>;
};

export const validateAssistantProcesses = (
  data: unknown
): Promise<AssistantProcess[]> => {
  return array().of(AssistantProcessSchema).validate(data) as Promise<
    AssistantProcess[]
  >;
};
