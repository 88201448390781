import { ChipProps, Chip as MuiChip } from "@mui/material";

export const Chip = ({ label, size = "tiny" }: ChipProps) => {
  return (
    <MuiChip
      label={label}
      className="bg-purple-100 text-purple-500 border-purple-500 border-solid border"
      size={size}
    />
  );
};
