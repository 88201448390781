import { useCreateDeviceMutation } from "@hooks";
import {
  ComponentConfigurationType,
  ComponentType,
  Device,
  Organization,
  Plan,
  SimpleSubscription,
  SubComponentConfigurationType,
  SubComponentType,
} from "@models";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Checkbox,
  Collapse,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { ComponentState, NewDeviceUIState, SubComponentState } from "@types";
import { scrollToElement } from "@utils";
import { useState } from "react";
import { CustomDeviceModalItemSection } from "src/components/DeviceCreation/modal-list-items/CustomDeviceModalComponents/CustomDeviceModalItemSection";
import { FreePlanLimitModalItemContent } from "src/components/DeviceCreation/modal-list-items/CustomDeviceModalComponents/FreePlanLimitModalItemContent";
import { HardwareComponentConfiguration } from "src/components/DeviceCreation/modal-list-items/CustomDeviceModalComponents/HardwareComponentConfiguration";
import { SoftwareComponentConfiguration } from "src/components/DeviceCreation/modal-list-items/CustomDeviceModalComponents/SoftwareComponentConfiguration";
import {
  getCleanDevice,
  initialElectricalSubComponentState,
  initialSoftwareSubComponentState,
  isFormInvalid,
  newHardwareComponentInitialState,
  newProductInitialState,
  newSoftwareComponentInitialState,
} from "src/components/DeviceCreation/modal-list-items/CustomDeviceModalComponents/utils";
import { YesNo } from "src/components/YesNo/YesNo";
import { shouldPayForNextNewDevice } from "src/utils/entitlements"; // TODO: Why does @utils import breaks tests?

export const CustomDeviceModalContent = ({
  subscription,
  devices,
  org,
}: {
  subscription: SimpleSubscription;
  devices: Device[];
  org: Organization;
}) => {
  const [newDevice, setNewDevice] = useState<NewDeviceUIState>(
    newProductInitialState
  );
  const [billingConfirmed, setBillingConfirmed] = useState(false);

  const createDeviceMutation = useCreateDeviceMutation();

  const updateProductComponent = (component: ComponentState) => {
    setNewDevice({
      ...newDevice,
      components: newDevice.components.map((c) =>
        c.id === component.id ? component : c
      ),
    });
  };

  const updateSubComponent = (
    component: ComponentState,
    subComponent: SubComponentState
  ) => {
    setNewDevice({
      ...newDevice,
      components: newDevice.components.map((c) =>
        c.id === component.id
          ? {
              ...c,
              subComponents: c.subComponents.map((sc) =>
                sc.id === subComponent.id ? subComponent : sc
              ),
            }
          : c
      ),
    });
  };

  const toggleProductComponentCollapse = (component: ComponentState) => {
    setNewDevice({
      ...newDevice,
      components: newDevice.components.map((c) =>
        c.id === component.id ? { ...c, collapsed: !c.collapsed } : c
      ),
    });

    scrollToElement(`component-${component.id}`);
  };

  const getComponentConfigurationValue = (
    component: ComponentState,
    type: ComponentConfigurationType
  ) => {
    const value = component.configuration.find(
      (item) => item.type === type
    )?.value;
    switch (value) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return value;
    }
  };

  const getSubComponentConfigurationValue = (
    subComponent: SubComponentState,
    type: SubComponentConfigurationType
  ) => {
    return subComponent.configuration.find((item) => item.type === type)?.value;
  };

  const hasHardware = newDevice.components.some(
    (c) => c.type === ComponentType.HARDWARE
  );

  const hasSoftware = newDevice.components.some(
    (c) => c.type === ComponentType.SOFTWARE
  );

  const addSubComponent = (
    type: SubComponentType,
    component: ComponentState,
    name: string
  ) => {
    const initialState =
      type === SubComponentType.SOFTWARE
        ? initialSoftwareSubComponentState
        : type === SubComponentType.ELECTRICAL
          ? initialElectricalSubComponentState
          : {
              type,
              name,
              description: "",
              configuration: [],
            };
    const newSubComponent = {
      id: component.subComponents.length + 1,
      ...initialState,
    };
    setNewDevice({
      ...newDevice,
      components: newDevice.components.map((c) =>
        c.id === component.id
          ? { ...c, subComponents: [...c.subComponents, newSubComponent] }
          : c
      ),
    });
  };

  const removeAllSubComponentsByType = (
    component: ComponentState,
    type: SubComponentType
  ) => {
    setNewDevice({
      ...newDevice,
      components: newDevice.components.map((c) =>
        c.id === component.id
          ? {
              ...c,
              subComponents: c.subComponents.filter((sc) => sc.type !== type),
            }
          : c
      ),
    });
  };

  const removeAllComponentsByType = (type: ComponentType) => {
    setNewDevice({
      ...newDevice,
      components: newDevice.components.filter((c) => c.type !== type),
    });
  };

  const updateComponentConfigurationValue = (
    component: ComponentState,
    type: ComponentConfigurationType,
    value: string
  ) => {
    updateProductComponent({
      ...component,
      // If the configuration already contains the item, update it, otherwise add it
      configuration: component.configuration.some((item) => item.type === type)
        ? component.configuration.map((item) =>
            item.type === type ? { ...item, value } : item
          )
        : [...component.configuration, { type, value }],
    });
  };

  const getHighestId = (components: ComponentState[]) => {
    return components.reduce(
      (max, component) => Math.max(max, component.id),
      0
    );
  };

  const addProductComponent = (type: ComponentType) => {
    const initialState =
      type === ComponentType.HARDWARE
        ? newHardwareComponentInitialState
        : newSoftwareComponentInitialState;

    const newComponent = {
      id: getHighestId(newDevice.components) + 1,
      ...initialState,
    };
    setNewDevice({
      ...newDevice,
      components: [
        ...newDevice.components.map((c) => ({ ...c, collapsed: true })),
        newComponent,
      ],
    });

    scrollToElement(`component-${newComponent.id}`);
  };

  const removeProductComponent = (component: ComponentState) => {
    setNewDevice({
      ...newDevice,
      components: newDevice.components.filter((c) => c.id !== component.id),
    });
  };

  const updateSubComponentConfigurationValue = (
    component: ComponentState,
    subComponent: SubComponentState,
    type: SubComponentConfigurationType,
    value: string
  ) => {
    updateSubComponent(component, {
      ...subComponent,
      configuration: subComponent.configuration.some(
        (item) => item.type === type
      )
        ? subComponent.configuration.map((item) =>
            item.type === type ? { ...item, value } : item
          )
        : [...subComponent.configuration, { type, value }],
    });
  };

  const nextDeviceIsPaidCreation = shouldPayForNextNewDevice(org, devices);

  const isFreePlan = subscription.products.includes(Plan.FREE);

  if (isFreePlan && nextDeviceIsPaidCreation) {
    return <FreePlanLimitModalItemContent orgId={org.id} />;
  }

  return (
    <div className="flex flex-col gap-y-6">
      <CustomDeviceModalItemSection>
        <TextField
          label="Device Name"
          required
          fullWidth
          value={newDevice?.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setNewDevice({ ...newDevice, name: event.target.value })
          }
        />
        <div className="flex flex-col gap-y-2">
          <Alert severity="info">
            Provide a detailed description of your device. Every detail, like
            special features, materials, or the users and patients can be
            essential in order to give you the best possible suggestions in the
            future.
          </Alert>
          <TextField
            label="Description"
            required
            fullWidth
            multiline
            minRows={3}
            value={newDevice?.description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewDevice({
                ...newDevice,
                description: event.target.value,
              });
            }}
          />
        </div>
        <YesNo
          question="Do you want to sell your device in the US?"
          value={!!newDevice.isFDA}
          onChange={(value) =>
            value === "true"
              ? setNewDevice({ ...newDevice, isFDA: true })
              : setNewDevice({ ...newDevice, isFDA: false })
          }
        />
        <YesNo
          question="Do you want to sell your device in the EU?"
          value={!!newDevice.isEU}
          onChange={(value) =>
            value === "true"
              ? setNewDevice({ ...newDevice, isEU: true, isMDR: true })
              : setNewDevice({ ...newDevice, isEU: false, isMDR: false })
          }
        />
        <Collapse in={!!newDevice.isEU}>
          <YesNo
            question="Does your device analyze human samples (e.g., blood, urine, tissue) to provide medical or diagnostic information (aka is your device an in vitro diagnostic device / IVD)?"
            value={!!newDevice.isIVDR}
            onChange={(value) =>
              value === "true"
                ? setNewDevice({ ...newDevice, isIVDR: true, isMDR: false })
                : setNewDevice({ ...newDevice, isIVDR: false, isMDR: true })
            }
          />
        </Collapse>
      </CustomDeviceModalItemSection>
      <CustomDeviceModalItemSection>
        <div>
          <Typography variant="h5">Hardware</Typography>
          <Alert severity="info" className="mb-2">
            A hardware component is a standalone physical part of the device
            that might contain electrical, mechanical , firmware or software
            parts. Examples are a measuring device, a smartwatch or a pacemaker.
          </Alert>
          <div>
            <YesNo
              question="Does the product have physical components?"
              value={!!hasHardware}
              onChange={(value) =>
                value === "true"
                  ? addProductComponent(ComponentType.HARDWARE)
                  : removeAllComponentsByType(ComponentType.HARDWARE)
              }
            />
          </div>
          {!!hasHardware && (
            <HardwareComponentConfiguration
              newDevice={newDevice}
              toggleProductComponentCollapse={toggleProductComponentCollapse}
              updateProductComponent={updateProductComponent}
              updateComponentConfigurationValue={
                updateComponentConfigurationValue
              }
              getComponentConfigurationValue={getComponentConfigurationValue}
              getSubComponentConfigurationValue={
                getSubComponentConfigurationValue
              }
              addSubComponent={addSubComponent}
              removeAllSubComponentsByType={removeAllSubComponentsByType}
              updateSubComponent={updateSubComponent}
              updateSubComponentConfigurationValue={
                updateSubComponentConfigurationValue
              }
              removeProductComponent={removeProductComponent}
              addProductComponent={addProductComponent}
            />
          )}
        </div>
      </CustomDeviceModalItemSection>
      <CustomDeviceModalItemSection>
        <div>
          <Typography variant="h5">Standalone Software</Typography>
          <Alert severity="info">
            A standalone software component might be a mobile app, a web app, or
            a software package that is not part of any hardware. If you have
            components that do separate things like a patient facing app and a
            practitioner web app you want to specify them as separate components
            so that you can handle releases independently.
          </Alert>
        </div>
        <YesNo
          question="Does the product have standalone software?"
          value={!!hasSoftware}
          onChange={(value) =>
            value === "true"
              ? addProductComponent(ComponentType.SOFTWARE)
              : removeAllComponentsByType(ComponentType.SOFTWARE)
          }
        />
        {!!hasSoftware && (
          <SoftwareComponentConfiguration
            newDevice={newDevice}
            toggleProductComponentCollapse={toggleProductComponentCollapse}
            updateProductComponent={updateProductComponent}
            updateComponentConfigurationValue={
              updateComponentConfigurationValue
            }
            getComponentConfigurationValue={getComponentConfigurationValue}
            removeProductComponent={removeProductComponent}
            addProductComponent={addProductComponent}
          />
        )}
      </CustomDeviceModalItemSection>
      <CustomDeviceModalItemSection>
        <div className="flex gap-x-4 justify-end">
          {nextDeviceIsPaidCreation && (
            <FormControlLabel
              control={
                <Checkbox
                  className="ml-1"
                  checked={billingConfirmed}
                  onChange={(e) => setBillingConfirmed(e.target.checked)}
                />
              }
              label="I confirm that I will be billed for this additional device"
              labelPlacement="start"
            />
          )}
          <LoadingButton
            disabled={
              isFormInvalid(newDevice) ||
              (nextDeviceIsPaidCreation && !billingConfirmed)
            }
            variant="contained"
            color="success"
            loading={createDeviceMutation.isPending}
            onClick={() =>
              createDeviceMutation.mutate({
                device: getCleanDevice(newDevice),
                orgId: org.id,
              })
            }
          >
            Create
          </LoadingButton>
        </div>
      </CustomDeviceModalItemSection>
    </div>
  );
};
