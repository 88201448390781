import { Document } from "@models";
import { Typography } from "@mui/material";
import { getAvailableDataKeys } from "@utils";
import Handlebars from "handlebars";
import { HelpBox } from "src/components/Form/HelpBox";
import { DocumentDataKey, TemplateElement } from "src/types";
export const StepHeader = ({
  templateElement,
  documents,
}: {
  templateElement: TemplateElement;
  documents: Document[];
}) => {
  if (!!templateElement.element.options.helperText) {
    // Extract all data keys and their answers from all the latest versions of the documents
    const dataKeys: Partial<Record<DocumentDataKey, string>> =
      getAvailableDataKeys(documents).reduce<
        Partial<Record<DocumentDataKey, string>>
      >(
        (acc, dataKey) => ({
          ...acc,
          [dataKey.id]: dataKey.value,
        }),
        {}
      );

    // IMPORTANT: This removes indentation from the helper text to make sure we are not accidentally showing things as codeblocks.
    const helperText = templateElement.element.options.helperText
      .split("\n")
      .map((line) => line.trim())
      .join("\n");
    // Use Handlebars to replace variables in the helper text
    const template = Handlebars.compile(helperText);
    templateElement.element.options.helperText = template(dataKeys);
  }

  return (
    <div className="flex flex-col gap-y-3">
      <Typography variant="h1">
        {templateElement.element.options.label}
      </Typography>
      {(!!templateElement.element.options.helperText ||
        templateElement.videoUrl) && (
        <HelpBox templateElement={templateElement} />
      )}
    </div>
  );
};
