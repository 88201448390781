export const downloadFile = async (data: Blob, filename: string) => {
  try {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(data);
    link.download = filename;
    link.click();
  } catch (error) {
    console.log(error);
  }
};

export function convertSVGtoImage(svgString: string) {
  return new Promise((resolve, reject) => {
    // var blob = new Blob([svgString], { type: "image/svg+xml" });
    var url = "data:image/svg+xml;base64," + btoa(svgString);

    let img = new Image();
    // img.crossOrigin = "anonymous";
    img.src = url;

    img.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = img.width * 1.3;
      canvas.height = img.height * 2;
      var ctx = canvas.getContext("2d");
      if (!ctx) throw new Error("Could not get canvas context");

      ctx.drawImage(img, 0, 0);

      try {
        var dataURL = canvas.toDataURL();
        if (dataURL === "data:,") {
          reject(new Error("Canvas is blank"));
        } else {
          resolve(dataURL);
        }
      } catch (e) {
        reject(e);
      }

      URL.revokeObjectURL(url);
    };

    img.onerror = function () {
      reject(new Error("Image loading error"));
      URL.revokeObjectURL(url);
    };
  });
}

export const removeSpecialCharactersFomFilename = (file: File) =>
  new File([file], file.name.replace(/[^\w.]|_/g, ""), {
    type: file.type,
  });
