export type StepConfig = {
  id: string;
  ignoreModalTitle?: boolean;
  ignoreModalActions?: boolean;
  customTitle?: string;
  buttonText?: string;
  inputFields?: InputField[];
  children?: React.ReactNode;
  title?: string;
  titleLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  subtitle?: string;
  buttonAlign?: "left" | "right";
};

export type InputField = {
  label: string;
  hint?: string;
  element: "input-group" | "chip";
  fieldId: string;
  options?: { option: string; id: string }[];
  multiple?: boolean;
};

export type Steps = StepConfig[];

export const steps: Steps = [
  {
    id: "start",
    ignoreModalTitle: true,
    ignoreModalActions: true,
    customTitle: "Welcome to FormlyAI",
    subtitle: "Your journey to a certified medical device starts now!",
    buttonText: "Let's start",
  },
  {
    id: "survey-name",
    title: "What is your name?",
    inputFields: [
      { label: "First Name", fieldId: "firstName", element: "input-group" },
      { label: "Last Name", fieldId: "lastName", element: "input-group" },
    ],
  },
  {
    id: "survey-1",
    inputFields: [
      {
        label: "What's the stage of your product?",
        element: "chip",
        fieldId: "productStage",
        options: [
          { option: "I just have an idea", id: "idea" },
          { option: "I'm in the development stage", id: "development" },
          {
            option: "A version of the product is on the market",
            id: "market",
          },
        ],
      },
      {
        label: "How far are you with it's certification?",
        hint: "(Select all that apply)",
        fieldId: "productCertification",
        multiple: true,
        element: "chip",
        options: [
          { option: "Not started", id: "notStarted" },
          { option: "In progress", id: "inProgress" },
          { option: "Already certified", id: "certified" },
          { option: "QMS only certified", id: "qmsCertified" },
        ],
      },
    ],
  },
  {
    id: "survey-2",
    inputFields: [
      {
        label: "What most interests you about Formly?",
        hint: "(Select all that apply)",
        fieldId: "productInterest",
        element: "chip",
        multiple: true,
        options: [
          { option: "eQMS", id: "eqms" },
          {
            option: "Free ISO 13485 Quality Management System",
            id: "iso13485",
          },
          { option: "Technical Documentation", id: "technicalDocumentation" },
          { option: "AI document completion", id: "aiDocumentCompletion" },
          { option: "Maintaining compliance", id: "maintainingCompliance" },
          {
            option: "I want to get my certification done quickly",
            id: "quickCertification",
          },
          { option: "I want guidance from start to finish", id: "guidance" },
          { option: "I don't know just curious", id: "curious" },
          {
            option: "I want to spend less time on writing documents",
            id: "lessTime",
          },
          { option: "Other", id: "other" },
        ],
      },
      {
        label: "Do you need a consultant along the way?",
        fieldId: "talkToExpert",
        element: "chip",
        options: [
          { option: "Yes", id: "yes" },
          { option: "No", id: "no" },
        ],
      },
      {
        label: "Do you want us to reach out for a product tour?",
        fieldId: "productTour",
        element: "chip",
        options: [
          { option: "Yes", id: "yes" },
          { option: "No", id: "no" },
        ],
      },
    ],
  },
  {
    id: "loading",
    ignoreModalTitle: true,
    ignoreModalActions: true,
  },
  {
    id: "video",
    subtitle:
      "Watch this video to learn more about how FormlyAI helps you get your medical device certified.",
    title: "How it works",
    titleLevel: "h1",
    buttonText: "Let's get started",
    buttonAlign: "right",
  },
];
