import {
  ComponentConfigurationType,
  ComponentType,
  Device,
  SubComponentType,
} from "@models";

export const hasSoftware = (device: Device): boolean => {
  return (
    device.components.some(
      (component) => component.type === ComponentType.SOFTWARE
    ) ||
    device.components.some((c) =>
      c.subComponents.some((sc) => sc.type === SubComponentType.SOFTWARE)
    )
  );
};

export const hasHardware = (device: Device): boolean => {
  return device.components.some(
    (component) => component.type === ComponentType.HARDWARE
  );
};

export const isSoftwareOnly = (device: Device): boolean => {
  return device.components.every(
    (component) => component.type === ComponentType.SOFTWARE
  );
};

export const hasFirmware = (device: Device): boolean => {
  return device.components.some((component) =>
    component.subComponents.some(
      (subComponent) => subComponent.type === SubComponentType.FIRMWARE
    )
  );
};

export const hasMechanical = (device: Device): boolean => {
  return device.components.some((component) =>
    component.subComponents.some(
      (subComponent) => subComponent.type === SubComponentType.MECHANICAL
    )
  );
};

export const hasElectrical = (device: Device): boolean => {
  return device.components.some((component) =>
    component.subComponents.some(
      (subComponent) => subComponent.type === SubComponentType.ELECTRICAL
    )
  );
};

export const hasImplantableComponent = (device: Device): boolean => {
  return device.components.some((c) =>
    c.configuration.some(
      (config) =>
        config.type === ComponentConfigurationType.IS_IMPLANTABLE &&
        config.value === "true"
    )
  );
};

export const hasSterileComponent = (device: Device): boolean => {
  return device.components.some(
    (c) =>
      c.configuration.some(
        (config) =>
          config.type ===
            ComponentConfigurationType.IS_STERILIZATION_INTENDED &&
          config.value === "true"
      ) ||
      device.components.some((c) =>
        c.subComponents.some((sc) =>
          sc.configuration.some(
            (config) =>
              config.type ===
                ComponentConfigurationType.IS_STERILIZATION_INTENDED &&
              config.value === "true"
          )
        )
      )
  );
};

export const isMDR = (device: Device): boolean => {
  return device.isMDR;
};

export const isFDA = (device: Device): boolean => {
  return device.isFDA;
};

export const isIVDR = (device: Device): boolean => {
  return device.isIVDR;
};

/**
 * Get normal (non demo) user devices.
 * @param devices
 * @returns
 */
export const getNonDemoDevices = (devices: Device[]): Device[] => {
  return devices.filter((device) => !device.isDemoDevice);
};
