import { useGetDevices, useGetUser } from "@hooks";
import { AddRounded } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getSubscription } from "@utils";
import { useParams, useSearchParams } from "react-router-dom";
import { DeviceCreationModal } from "src/components/DeviceCreation/DeviceCreationModal";
import { getOrgFromUser } from "src/utils/user";
import { DashboardSkeleton, DeviceList } from "../components";
import { useStore } from "../stores";

export const DEVICE_MODAL_SEARCH_PARAM = "device_modal";

export const addDeviceModalSearchParam = (
  expandedOption: "demo" | "custom"
) => {
  return "?device_modal=" + (expandedOption === "demo" ? "0" : "1");
};

function Dashboard() {
  const { deviceStore } = useStore();
  const { data: user } = useGetUser();
  const { orgId = "" } = useParams<{
    orgId: string;
  }>();
  const { data: devices, isLoading: isLoadingDevices } = useGetDevices({
    orgId,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const setExpandedId = (id: number) => {
    setSearchParams({ device_modal: id.toString() });
  };

  const org = getOrgFromUser(user, orgId);
  const subscription = org ? getSubscription(org) : null;

  // Prefetch roadmap tasks for each displayed device
  useQuery({
    queryKey: ["tasks"],
    queryFn: () =>
      devices &&
      Promise.all(
        devices.map(async (device) =>
          deviceStore.fetchRoadmapTasks(orgId, device.id)
        )
      ),
    enabled: !!devices,
  });

  if ((isLoadingDevices && !devices) || !user || !devices || !subscription) {
    return <DashboardSkeleton />;
  }

  const isDeviceListEmpty =
    !isLoadingDevices && devices && devices.length === 0;

  return (
    <>
      <div className="flex flex-1 flex-col p-0">
        <div className="mb-3 flex flex-row justify-between">
          <Typography variant="h1">Device Library</Typography>
          {!isDeviceListEmpty && (
            <Button
              variant="contained"
              endIcon={<AddRounded />}
              onClick={async () => {
                setSearchParams({ device_modal: "0" });
              }}
            >
              Add Device
            </Button>
          )}
        </div>

        {!isDeviceListEmpty && <DeviceList devices={devices} />}

        {isDeviceListEmpty && (
          <div className="flex max-w-lg flex-1 flex-col items-center justify-center self-center text-center gap-4">
            <Typography variant="body1">
              To start your journey click the button below and this will create
              your first device and lead you right into the guided technical
              file.
            </Typography>
            <Button
              variant="contained"
              endIcon={<AddRounded />}
              onClick={async () => {
                setSearchParams({ device_modal: "0" });
              }}
            >
              Add Device
            </Button>
          </div>
        )}
      </div>
      <DeviceCreationModal
        open={searchParams.get(DEVICE_MODAL_SEARCH_PARAM) !== null}
        onClose={() => setSearchParams({})}
        subscription={subscription}
        user={user}
        devices={devices}
        orgId={orgId}
        setExpandedId={setExpandedId}
        expandedId={parseInt(
          searchParams.get(DEVICE_MODAL_SEARCH_PARAM) || "0"
        )}
      />
    </>
  );
}

export default Dashboard;
