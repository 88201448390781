import { Chip } from "@components";
import { ROUTES } from "@config";
import {
  useGetAssistantProcesses,
  useGetDevice,
  useGetDocuments,
  useUpdateAssistantProcessMutation,
} from "@hooks";
import {
  AssistantProcess,
  AssistantProcessStatus,
  Device,
  Document,
} from "@models";
import { Cancel } from "@mui/icons-material";
import {
  Alert,
  Box,
  Checkbox,
  Link,
  MenuItem,
  Chip as MuiChip,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { fillRouteWithSlugs } from "@utils";
import { Crisp } from "crisp-sdk-web";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentUpdateSkeleton } from "src/components/Assistant/DocumentUpdateSkeleton";
import { NavigationButtons } from "src/components/Assistant/NavigationButtons";
import {
  changeTypeToTemplates,
  getDataKeysToUpdate,
} from "src/components/Assistant/utils";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { useCrisp } from "src/hooks/Crisp";
import { SelectElement, TextFieldElement } from "../../../types";

export const optionsToChangeType: Record<
  string,
  keyof typeof changeTypeToTemplates
> = {
  "Bug fixes to restore the function of the product to it's original intended state":
    "bugFix",
  "Adjustments to existing features or visual improvements": "patch",
  "Features added that don't change the intended use of the product": "minor",
  "New features or visual improvements that change the intended use of the product":
    "major",
};

const selectElement: SelectElement = {
  type: "select",
  id: "document-update-type",
  options: {
    label: "",
    multiple: true,
    options: Object.keys(optionsToChangeType),
  },
};

const textElement: TextFieldElement = {
  type: "textField",
  id: "document-update-description",
  options: {
    label: "Describe the changes",
    helperText:
      "You can paste your changelog here or any other relevant information about the changes you are making.",
    rows: 4,
  },
};

export const DU_Configure = () => {
  const { orgId = "", deviceId = "" } = useParams();
  const navigate = useNavigate();
  const { setSupportOpen } = useCrisp();
  const { data: documents } = useGetDocuments(orgId, deviceId);
  const { data: assistantProcesses } = useGetAssistantProcesses({
    orgId,
    deviceId,
    status: AssistantProcessStatus.IN_PROGRESS,
  });
  const { data: device } = useGetDevice({ orgId, deviceId });
  const { mutateAsync: updateAssistantProcess } =
    useUpdateAssistantProcessMutation(orgId, deviceId);

  const assistantProcess = assistantProcesses?.[0] || null;

  const [description, setDescription] = useState("");
  const [changeType, setChangeType] = useState<string[]>([]);

  const handleChangeType = (
    assistantProcess: AssistantProcess,
    documents: Document[],
    device: Device,
    event: SelectChangeEvent<string[]>
  ) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === "string" ? value.split(",") : value;
    setChangeType(selectedValues);
    updateAssistantProcess({
      processId: assistantProcess.id,
      body: {
        state: {
          ...assistantProcess.state,
          currentPath: ROUTES.ASSISTANT_DU_LIST_OF_DOCS_TO_CHANGE,
          checkboxes: selectedValues,
          dataKeysToUpdate: getDataKeysToUpdate(
            selectedValues,
            documents,
            device
          ),
        },
      },
    });
  };

  const handleNext = (
    documents: Document[],
    device: Device,
    assistantProcess: AssistantProcess
  ) => {
    const dataKeysToUpdate = getDataKeysToUpdate(changeType, documents, device);

    updateAssistantProcess({
      processId: assistantProcess.id,
      body: {
        state: {
          ...assistantProcess.state,
          currentPath: ROUTES.ASSISTANT_DU_LIST_OF_DOCS_TO_CHANGE,
          checkboxes: changeType,
          description,
          dataKeysToUpdate,
        },
      },
    });

    navigate(
      fillRouteWithSlugs(ROUTES.ASSISTANT_DU_LIST_OF_DOCS_TO_CHANGE, {
        [ROUTE_SLUGS.ORG_ID]: orgId,
        [ROUTE_SLUGS.DEVICE_ID]: deviceId,
      })
    );
  };

  const handleSupportClick = () => {
    Crisp.message.setMessageText(
      "I have made major changes to my device and I need help to update the correct documents."
    );
    setSupportOpen(true);
  };

  const majorChanges = selectElement.options.options?.[3] || "";
  const hasUserSelectedMajorChanges = changeType.includes(majorChanges);
  const isFormValid =
    changeType.length > 0 &&
    !hasUserSelectedMajorChanges &&
    description.trim() !== "";

  if (
    !assistantProcess ||
    !documents ||
    !device ||
    assistantProcesses?.[0].status !== AssistantProcessStatus.IN_PROGRESS
  ) {
    return <DocumentUpdateSkeleton />;
  }

  return (
    <div className="flex flex-1 flex-col gap-y-6">
      <div className="flex flex-col gap-y-3">
        <Typography variant="h1" className="flex items-center gap-2">
          Assistant <Chip label="beta" />
        </Typography>
        <Typography variant="body1">
          Tell us about your change and we will help you update the correct
          documents.
        </Typography>
      </div>
      <div className="flex flex-col gap-y-4">
        <div>
          <Typography>
            Choose which description best describes your changes
          </Typography>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={changeType}
            className="w-full"
            onChange={(event) =>
              handleChangeType(assistantProcess, documents, device, event)
            }
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  gap: 1,
                }}
              >
                {selected.map((value) => (
                  <MuiChip
                    key={value}
                    label={value}
                    color={value === majorChanges ? "error" : "default"}
                    variant={value === majorChanges ? "outlined" : "filled"}
                    deleteIcon={
                      <Cancel
                        onMouseDown={(event: React.MouseEvent<SVGSVGElement>) =>
                          event.stopPropagation()
                        }
                      />
                    }
                    onDelete={async () => {
                      const newSelected = selected.filter((v) => v !== value);
                      handleChangeType(assistantProcess, documents, device, {
                        target: { value: newSelected },
                      } as SelectChangeEvent<string[]>);
                    }}
                  />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                width: "100%",
                style: {
                  width: "fit-content",
                },
              },
            }}
          >
            {selectElement.options.options?.map((name) => (
              <MenuItem key={name} value={name}>
                {selectElement.options.multiple && (
                  <Checkbox checked={changeType.indexOf(name) > -1} />
                )}
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
        {hasUserSelectedMajorChanges && (
          <Alert severity="error">
            You have selected a major change, this process does not support
            those changes yet. Please{" "}
            <Link className="cursor-pointer" onClick={handleSupportClick}>
              click here
            </Link>{" "}
            to contact one of our experts for assistance.
          </Alert>
        )}
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-col gap-y-1">
            <Typography>{textElement.options.label}</Typography>
            <Typography variant="body2" color="text.secondary">
              {textElement.options.helperText}
            </Typography>
          </div>
          <TextField
            onChange={(change) => setDescription(change.target.value)}
            hiddenLabel
            multiline
            fullWidth
            minRows={4}
            value={description}
            inputProps={{
              "data-testid": "document-update-description",
            }}
          />
        </div>
      </div>

      <NavigationButtons
        leftButton={{
          hasConfirmationModal: true,
        }}
        rightButton={{
          disabled: !isFormValid || !assistantProcess,
          onClick: () => handleNext(documents, device, assistantProcess),
        }}
      />
    </div>
  );
};
