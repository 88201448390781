import { AssistantProcess } from "@models";
import {
  ChevronRight,
  CompareArrows,
  ContentCopy,
  ReplayRounded,
  VisibilityOff,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { diffWords } from "diff";
import { useState } from "react";
import {
  DocumentDataKey,
  TemplateElement,
  Suggestion as TSuggestion,
  WorkflowState,
} from "../../types";
import { SuggestionLoadingIndicator } from "./SuggestionLoadingIndicator";
type Props = {
  suggestion: TSuggestion;
  workflowState: WorkflowState;
  step: TemplateElement;
  handleApply?: (stepId: DocumentDataKey, suggestion: string) => void;
  onRegenerateSuggestion: () => void;
  boxTitle?: string;
  buttonProps?: {
    color?: "success" | "primary";
    text?: string;
  };
  assistantProcess?: AssistantProcess;
  fullWidth?: boolean;
};
export const Suggestion = ({
  step,
  handleApply = (_, _1) => {},
  onRegenerateSuggestion,
  suggestion,
  workflowState,
  boxTitle = "Suggestion",
  buttonProps,
  assistantProcess,
  fullWidth = false,
}: Props) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [diffHtml, setDiffHtml] = useState<JSX.Element[]>([]);

  const runDiff = () => {
    if (!assistantProcess) {
      return;
    }
    const dataKeyChangeState = assistantProcess.state.changes.find(
      (change) => change.dataKeyId === step.id
    );
    const oldText = dataKeyChangeState?.previousAnswer?.answer;
    const newText =
      dataKeyChangeState?.acceptedAnswer?.answer ||
      dataKeyChangeState?.suggestion?.value;

    if (!oldText || !newText) {
      return;
    }

    const diff = diffWords(oldText, newText);
    const displayHtml = diff.map((part, index) => {
      let className = "";
      if (part.added) className = "added";
      else if (part.removed) className = "removed";
      else className = "unchanged";

      return (
        <span key={index} className={className}>
          {part.value}
        </span>
      );
    });
    setDiffHtml(displayHtml);
  };

  const copySuggestionToClipboard = async (suggestion: string) => {
    const blobHtml = new Blob([suggestion], { type: "text/html" });
    const blobText = new Blob([suggestion], { type: "text/plain" });
    const data = [
      new ClipboardItem({
        ["text/plain"]: blobText,
        ["text/html"]: blobHtml,
      }),
    ];

    navigator.clipboard.write(data);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  const hasError = suggestion.error || suggestion.isStatusMessage;

  const renderShowOrHideDiffIcon = () => {
    if (!assistantProcess) {
      return null;
    }
    if (diffHtml.length > 0) {
      return (
        <Tooltip title="Hide diff" placement="left">
          <IconButton size="small" onClick={() => setDiffHtml([])}>
            <VisibilityOff />
          </IconButton>
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Show diff" placement="left">
        <IconButton size="small" onClick={runDiff}>
          <CompareArrows />
        </IconButton>
      </Tooltip>
    );
  };

  const renderDiff = () => {
    return (
      <>
        <div className="rounded-sm border border-gray-200 border-solid px-3 py-4 bg-white">
          {diffHtml.map((part, index) => {
            let className = part.props.className;
            const tailwindClass =
              className === "added"
                ? "bg-green-100"
                : className === "removed"
                  ? "bg-red-100 line-through"
                  : "";

            return (
              <span key={index} className={tailwindClass}>
                {part.props.children}
              </span>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className={`flex ${fullWidth ? "w-full" : "w-1/2"} flex-col`}>
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center justify-between">
          <Typography variant="body1" className="font-bold leading-[34px]">
            {boxTitle}
          </Typography>
          <div className="flex items-center gap-2">
            <Tooltip title="Regenerate suggestion" placement="left">
              <IconButton size="small" onClick={onRegenerateSuggestion}>
                <ReplayRounded />
              </IconButton>
            </Tooltip>
            {typeof suggestion.value === "string" && (
              <Tooltip
                title={copySuccess ? "Copied" : "Copy suggestion"}
                placement="left"
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    typeof suggestion.value === "string" &&
                    copySuggestionToClipboard(suggestion.value)
                  }
                >
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            )}
            {renderShowOrHideDiffIcon()}
          </div>
        </div>
      </div>
      {hasError ? (
        <div className="h-[60px] rounded bg-gray-100 px-3 py-4">
          <Typography className="text-red-500">{suggestion.value}</Typography>
        </div>
      ) : (
        <>
          <div className="mb-4 relative">
            {diffHtml.length > 0 ? (
              renderDiff()
            ) : (
              <>
                <SuggestionLoadingIndicator
                  suggestion={suggestion}
                  workflowState={workflowState}
                />

                <MarkdownPreview
                  source={suggestion.value || ""}
                  className="bg-gray-100  text-base py-4 px-3 border border-gray-200 rounded-md border-solid min-h-[53px] w-full"
                  wrapperElement={{ "data-color-mode": "light" }}
                />

                {/* <TextField
                  hiddenLabel
                  type="text"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSuggestionValue(event.target.value);
                  }}
                  multiline={true}
                  fullWidth
                  value={getSuggestionValue()}
                  InputProps={{
                    readOnly: readOnly,
                    //TODO: Fix css for readonly text field
                  }}
                /> */}
              </>
            )}
          </div>
          {step.element.type === "textField" && (
            <div className="flex self-end">
              <Button
                variant="contained"
                endIcon={<ChevronRight />}
                color={buttonProps?.color || "success"}
                disabled={!suggestion.completed}
                onClick={() =>
                  typeof suggestion.value === "string" &&
                  handleApply(step.id, suggestion.value)
                }
              >
                {buttonProps?.text || "Apply"}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
