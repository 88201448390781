import { Skeleton } from "@mui/material";
import { useState } from "react";

interface LoomVideoProps {
  src: string;
  title?: string;
  width?: number | string;
  height?: number | string;
  className?: string;
}

export function LoomVideo({
  src,
  width = "100%",
  height = "100%",
  className = "",
}: LoomVideoProps) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div
      className={className}
      style={{
        position: "relative",
        width: width,
        height: height,
        flex: "1 1 auto", // Allow the container to grow and shrink
      }}
    >
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            className="rounded-md"
          />
        </div>
      )}
      <iframe
        data-testid="loom-video"
        src={src}
        allowFullScreen
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          position: "absolute",
          top: 0,
          left: 0,
          borderRadius: "6px",
        }}
        onLoad={() => setIsLoading(false)}
      />
    </div>
  );
}
