import { getCalApi } from "@calcom/embed-react";
import { Alert, AlertColor, Button } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const Banner = ({
  bannerText,
  rightText = "Talk to us",
  buttonText,
  buttonLink,
  color = "warning",
}: {
  bannerText: string;
  buttonText: string;
  buttonLink: string;
  color?: AlertColor;
  rightText?: string;
}) => {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi({ namespace: "formlyai-free-trial-meeting" });
      cal("ui", {
        theme: "light",
        styles: { branding: { brandColor: "#074EE8" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);
  return (
    <Alert
      severity={color}
      icon={false}
      className={`w-full h-[var(--banner-height)] fixed top-0 animate-fade-down animate-duration-500 px-5`}
      action={
        <div className="flex flex-1 items-center gap-3 shrink-0 mr-2">
          {rightText && (
            <div
              className="cursor-pointer hover:underline"
              color="inherit"
              data-cal-namespace="formlyai-free-trial-meeting"
              data-cal-link="team/formly/formlyai-free-trial-meeting"
              data-cal-config='{"layout":"month_view","theme":"light"}'
            >
              {rightText}
            </div>
          )}
          <Button
            color="inherit"
            size="small"
            variant="outlined"
            component={Link}
            to={buttonLink}
          >
            {buttonText}
          </Button>
        </div>
      }
      sx={{
        display: "flex",
        alignItems: "center",
        ".MuiAlert-message": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          flex: 1,
          marginRight: "16px",
        },
        ".MuiAlert-action": {
          padding: 0,
          marginLeft: 6,
        },
      }}
    >
      {bannerText}
    </Alert>
  );
};
