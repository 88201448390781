import {
  useAnswerHistory,
  useDebounce,
  useDocEngine,
  usePatchDocumentVersionMutation,
  useSaveSuggestion,
} from "@hooks";
import { ChevronRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { hasInlineSuggestion } from "@utils";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { StepHeader } from "src/components/Form/StepHeader";
import { useSaveAnswer } from "src/components/FormStep/utils/useSaveAnswer";
import { isStepComplete } from "src/utils/step";
import {
  Device,
  Document,
  DocumentVersion,
  TEMPLATE_TYPE,
  User,
} from "../../stores/models";
import { StepValue, TemplateElement } from "../../types";
import { ElementMapper } from "../Form/ElementMapper";
import { Suggestion } from "../Form/Suggestion";
import {
  getAnswer,
  getLatestSavedSuggestion,
  runTransformer,
} from "../Form/utils";
import useSuggestion, { defaultSuggestion } from "./utils/useSuggestion";

type Props = {
  templateId: TEMPLATE_TYPE;
  activeStep: TemplateElement;
  steps: TemplateElement[];
  step: TemplateElement;
  containerRef: React.RefObject<HTMLDivElement>;
  device: Device;
  documentVersion: DocumentVersion;
  documents: Document[];
  user: User;
};

export const MultiStepFormStep = ({
  templateId,
  activeStep,
  device,
  documents,
  documentVersion,
  step,
  steps,
  user,
}: Props) => {
  const {
    orgId = "",
    deviceId = "",
    docId = "",
  } = useParams<{
    orgId: string;
    deviceId: string;
    templateId: TEMPLATE_TYPE;
    docId?: string;
  }>();

  let [_, setSearchParams] = useSearchParams();

  const isVisible = activeStep.id === step.id;
  const isLastStep = activeStep.id === steps[steps.length - 1].id;
  const isAnswerStringElement =
    step.element.type !== "answerItemsElement" &&
    step.element.type !== "fileUpload";

  const saveSuggestion = useSaveSuggestion();
  const patchDocumentMutation = usePatchDocumentVersionMutation();
  const savedSuggestion = getLatestSavedSuggestion(step, documentVersion);
  const { saveAnswer } = useSaveAnswer(orgId, deviceId);

  const {
    suggestion: newSuggestion,
    workflowState,
    applySuggestion,
    handleGenerateSuggestion,
  } = useSuggestion({
    step,
    documentVersion,
    device,
    documents,
    savedSuggestion:
      typeof savedSuggestion === "string"
        ? {
            ...defaultSuggestion,
            value: savedSuggestion,
          }
        : undefined,
    assistantProcess: undefined,
    enabled: isVisible,
  });

  const docEngine = useDocEngine(deviceId);

  const { debounce, cancelDebounce } = useDebounce(2000, (value: StepValue) => {
    if (isAnswerStringElement) {
      saveAnswer(docId, value, step, documentVersion);
    }
  });

  const [isGenerating, setIsGenerating] = useState(false);
  const [stepValue, setStepValue] = useState<StepValue>(
    getAnswer({
      step,
      documentVersion,
      documents,
    })
  );

  const handleForceRunTransformer = () => {
    const newAnswer = runTransformer({
      step,
      documentVersion,
      documents,
    });
    setStepValue({ answer: newAnswer, answerFileId: undefined });
  };

  const nextStep = (
    step: TemplateElement,
    answer: StepValue,
    documentVersion: DocumentVersion,
    steps: TemplateElement[]
  ) => {
    const nextStep = steps[steps.findIndex((s) => s.id === step.id) + 1];
    setSearchParams((params) => ({ ...params, step: nextStep.id }));
    // Cancel the debounce to make sure it is not conflicting with the next step or any network requests that might be happening
    cancelDebounce();
    saveAnswer(docId, answer, step, documentVersion);
  };

  const [updateHistory, undo, redo] = useAnswerHistory({
    elementId: step.id,
  });

  const handleChange = (_: TemplateElement, value: StepValue) => {
    setStepValue(value);
    debounce(value);
  };

  const handleUndo = () => {
    const newAnswer = undo();
    if (newAnswer) {
      handleChange(step, { answer: newAnswer });
    }
  };

  const handleRedo = () => {
    const newAnswer = redo();
    if (newAnswer) {
      handleChange(step, { answer: newAnswer });
    }
  };

  // Set suggestion to answer on changes for inline suggestions
  useEffect(() => {
    if (hasInlineSuggestion(step) && typeof newSuggestion.value === "string") {
      handleChange(step, {
        answer: newSuggestion.value,
        answerFileId: undefined,
        answerItems: undefined,
      });
    }

    if (newSuggestion.completed && typeof newSuggestion.value === "string") {
      saveSuggestion.mutate({
        orgId,
        suggestion: newSuggestion.value,
        documentVersion,
        device,
        docId,
        step,
      });

      // If the suggestion is inline, save the answer as well as the newest suggestion is always also the answer
      if (hasInlineSuggestion(step)) {
        saveAnswer(
          docId,
          { answer: newSuggestion.value },
          step,
          documentVersion
        );
      }
    }
  }, [newSuggestion]);

  const isFetchingSuggestion = newSuggestion.loading;

  useEffect(() => {
    if (isAnswerStringElement) {
      updateHistory(stepValue.answer);
    }
  }, [stepValue]);

  const handleNextStep = () => {
    nextStep(step, stepValue, documentVersion, steps);
  };

  const isNextStepDisabled =
    !isStepComplete(step, stepValue) || isGenerating || isFetchingSuggestion;

  const handleApplySuggestion = (step: TemplateElement, suggestion: string) => {
    applySuggestion();

    handleChange(step, { answer: suggestion });
  };

  const handleFinalStep = async (
    step: TemplateElement,
    answer: StepValue,
    docVersion: DocumentVersion,
    device: Device,
    documents: Document[],
    user: User
  ) => {
    setIsGenerating(true);

    // Set the status of the document version to completed for the local state to reflect the change and make the is completed check work
    docVersion.readyForApproval = true;

    // Cancel the debounce to make sure it is not conflicting with the next step or any network requests that might be happening
    cancelDebounce();

    // Save the answer for the last step
    const savedAnswer = await saveAnswer(docId, answer, step, docVersion);

    // TODO move this to onMutate or onSuccess of the saveAnswerMutation
    if (savedAnswer && "element" in savedAnswer) {
      // Replace the answer in the document version with the saved answer
      docVersion.answers = docVersion.answers.map((a) =>
        a.element === step.id ? savedAnswer : a
      );

      // Or add the answer if it does not exist yet in the document version add the answer
      if (!docVersion.answers.find((a) => a.element === step.id)) {
        docVersion.answers.push(savedAnswer);
      }
    }

    //   Update the document version status to completed
    await patchDocumentMutation.mutateAsync({
      orgId,
      docId: docId,
      deviceId: deviceId,
      docVersionId: docVersion.id,
      data: { readyForApproval: true },
    });

    await docEngine.navigateToWizardOrQMS({
      templateId,
      documentId: docId,
      device,
      documents,
      user,
    });
  };

  if (!isVisible) {
    return null;
  }

  return (
    // replacing div with slider for now since it caused performance issues
    <div key={step.id} className={`w-full flex flex-1 flex-col gap-y-2`}>
      <StepHeader templateElement={step} documents={documents} />
      <div className="flex w-full flex-1 flex-row items-start gap-4">
        {!hasInlineSuggestion(step) && step.prompt && (
          <Suggestion
            suggestion={newSuggestion}
            step={step}
            handleApply={(_, suggestion) =>
              handleApplySuggestion(step, suggestion)
            }
            onRegenerateSuggestion={() => {
              handleGenerateSuggestion();
            }}
            workflowState={workflowState}
          />
        )}
        <div
          className={`flex ${
            hasInlineSuggestion(step) ? "w-full" : "w-1/2"
          } h-full flex-1 flex-col gap-y-4`}
        >
          <ElementMapper
            step={step}
            suggestion={newSuggestion}
            onChange={handleChange}
            hasInlineSuggestion={hasInlineSuggestion(step)}
            stepValue={stepValue}
            onRegenerateSuggestion={handleGenerateSuggestion}
            onReRunTransformer={handleForceRunTransformer}
            onRedo={handleRedo}
            onUndo={handleUndo}
            documentVersion={documentVersion}
            templateId={templateId}
          />
          <div className="self-end">
            {!isLastStep && (
              <Button
                onClick={handleNextStep}
                disabled={isNextStepDisabled}
                variant="contained"
                endIcon={<ChevronRight />}
              >
                Next
              </Button>
            )}
            {/* If last step, show generate button */}
            {isLastStep && (
              <LoadingButton
                variant="contained"
                loading={isGenerating}
                disabled={isNextStepDisabled}
                onClick={() =>
                  handleFinalStep(
                    step,
                    stepValue,
                    documentVersion,
                    device,
                    documents,
                    user
                  )
                }
              >
                Generate
              </LoadingButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
