import { TemplateElement } from "@types";
import { WizardTopBar } from "./WizardTopBar";

export const AssistantMultiStepFormTopBar = ({
  activeStep,
  steps,
  nextStep,
  prevStep,
  endButtonOnClick,
  isReadyForNextStep,
}: {
  activeStep: TemplateElement;
  steps: TemplateElement[];
  nextStep: () => void;
  prevStep: () => void;
  endButtonOnClick: () => void;
  isReadyForNextStep: boolean;
}) => {
  const isLastStep = activeStep.id === steps[steps.length - 1].id;
  const isFirstStep = activeStep.id === steps[0].id;

  return (
    <WizardTopBar
      forwardDisabled={isLastStep || !isReadyForNextStep}
      backDisabled={isFirstStep}
      nextStep={nextStep}
      prevStep={prevStep}
      leftButton={{
        onClick: endButtonOnClick,
        text: "Cancel Assistant",
      }}
    />
  );
};
