import { useDebounceQueryMutation } from "@hooks";
import {
  AssistantProcess,
  BillingInterval,
  Device,
  Document,
  DocumentAnswer,
  DocumentAnswerItemSchema,
  DocumentAnswerSchema,
  DocumentAnswerSuggestionSchema,
  DocumentSchema,
  DocumentVersion,
  DocumentVersionApprover,
  DocumentVersionSchema,
  FileSchema,
  FullUserUiStateSchema,
  PatchAssistantProcessBody,
  Plan,
  RoadmapTasks,
  TEMPLATE_TYPE,
  UpdatableUiState,
  User,
  UserSurveyAnswers,
  UserSurveyAnswersSchema,
  deviceSchema,
  validateAssistantProcess,
} from "@models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fillRouteWithSlugs } from "@utils";
import { orderBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { removeSpecialCharactersFomFilename } from "src/utils/files";
import {
  ASSISTANT_CONFIG,
  ROUTES,
  getAllFilesInOrgQueryKey,
  getAnswerItemsQueryKey,
  getApproversQueryKey,
  getAssistantProcessesQueryKey,
  getCreateDocumentMutationKey,
  getDevicesQueryKey,
  getDocumentQueryKey,
  getDocumentsQueryKey,
  getFileQueryKey,
  getOrganizationUsersQueryKey,
  getPatchApproverStatusMutationKey,
  getTasksQueryKey,
  getUserQueryKey,
  getUserSurveyAnswersQueryKey,
  getUserTodosQueryKey,
} from "../config";
import {
  checkout,
  createDocument,
  createDocumentVersion,
  deleteDocumentApprover,
  deleteDocumentVersion,
  deleteFile,
  generatePdf,
  patchApproverStatus,
  patchAssistantProcess,
  patchDocumentAnswerItem,
  patchDocumentVersion,
  patchRoadmapTasks,
  patchUserInOrg,
  patchUserSurveyAnswers,
  postAssistantProcess,
  postBillingSession,
  postDemoDevice,
  postDevice,
  postDocumentAnswer,
  postDocumentAnswerItem,
  postDocumentAnswerSuggestion,
  postDocumentApprover,
  postUserInvite,
  updateOrg,
  updateUserUiState,
  uploadFile,
} from "../services";
import {
  AnswerItem,
  CreateDemoDeviceRequest,
  Fields,
  NewDevice,
  PatchDocumentVersionData,
  TemplateElement,
} from "../types";

export const useSaveSuggestion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      orgId,
      suggestion,
      documentVersion,
      step,
      device,
      docId,
    }: {
      orgId: string;
      suggestion: string;
      documentVersion: DocumentVersion;
      device: Device;
      docId: string;
      step: TemplateElement;
    }) => {
      const { data } = await postDocumentAnswerSuggestion(
        orgId,
        device.id,
        docId,
        documentVersion.id,
        {
          suggestion,
          element: step.id,
        }
      );
      return await DocumentAnswerSuggestionSchema.validate(data);
    },
    onSuccess: (_, { device, docId }) => {
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(device.id, docId),
      });
    },
  });
};

export const usePatchDocumentVersionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      orgId,
      docId,
      docVersionId,
      deviceId,
      data,
    }: {
      orgId: string;
      docId: string;
      docVersionId: string;
      deviceId: string;
      data: PatchDocumentVersionData;
    }) => {
      const { data: newDoc } = await patchDocumentVersion(
        orgId,
        deviceId,
        docId,
        docVersionId,
        data
      );
      return DocumentVersionSchema.validateSync(newDoc);
    },
    onMutate: async ({ docId, docVersionId, deviceId, data }) => {
      const previousDoc: Document | undefined = queryClient.getQueryData(
        getDocumentQueryKey(deviceId, docId)
      );

      const previousDocs: Document[] | undefined = queryClient.getQueryData(
        getDocumentsQueryKey(deviceId)
      );

      if (!previousDoc || !previousDocs) {
        return;
      }

      const documentVersion = previousDoc.versions.find(
        (v) => v.id === docVersionId
      );

      if (!documentVersion) {
        return;
      }

      const updatedVersion = {
        ...documentVersion,
        ...data,
      };

      const updatedDoc = {
        ...previousDoc,
        versions: [
          updatedVersion,
          ...previousDoc.versions.filter((v) => v.id !== docVersionId),
        ],
      };

      queryClient.setQueryData(
        getDocumentQueryKey(deviceId, docId),
        updatedDoc
      );

      // Update the version status in the documents list local cache
      queryClient.setQueryData(getDocumentsQueryKey(deviceId), () => [
        ...previousDocs.filter((d) => d.id !== docId),
        updatedDoc,
      ]);

      return { previousDoc: previousDoc, previousDocs: previousDocs };
    },
    onError: (_, __, context) => {
      if (context?.previousDoc) {
        queryClient.setQueryData(
          getDocumentQueryKey(
            context.previousDoc.deviceId,
            context.previousDoc.id
          ),
          context.previousDoc
        );

        queryClient.setQueryData(
          getDocumentsQueryKey(context.previousDoc.deviceId),
          context.previousDocs
        );
      }
    },
    onSuccess: (_, { deviceId, docId }) => {
      queryClient.invalidateQueries({
        queryKey: getUserTodosQueryKey(deviceId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentsQueryKey(deviceId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(deviceId, docId),
      });
    },
  });
};

export const useCreateDemoDeviceMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async ({
      request,
      orgId,
    }: {
      request: CreateDemoDeviceRequest;
      orgId: string;
    }) => {
      const { data } = await postDemoDevice(orgId, request);
      return await deviceSchema.validate(data);
    },
    onSuccess: (data, { orgId }) => {
      queryClient.setQueryData(getDevicesQueryKey(orgId), (old: Device[]) => [
        ...old,
        data,
      ]);
      navigate(
        fillRouteWithSlugs(ROUTES.DEVICE_OVERVIEW, {
          [ROUTE_SLUGS.DEVICE_ID]: data.id,
          [ROUTE_SLUGS.ORG_ID]: orgId,
        })
      );
    },
  });
};

export const useUpdateUserSurveyAnswersMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      data,
    }: {
      data: Partial<UserSurveyAnswers>;
      userId: string;
    }) => {
      const { data: updatedData } = await patchUserSurveyAnswers(data);
      return await UserSurveyAnswersSchema.validate(updatedData);
    },
    onMutate: (data: { data: Partial<UserSurveyAnswers>; userId: string }) => {
      queryClient.cancelQueries({
        queryKey: getUserSurveyAnswersQueryKey(data.userId),
      });

      const previousData = queryClient.getQueryData<UserSurveyAnswers>(
        getUserSurveyAnswersQueryKey(data.userId)
      );

      queryClient.setQueryData(
        getUserSurveyAnswersQueryKey(data.userId),
        (old: UserSurveyAnswers | undefined) => ({
          ...old,
          ...data.data,
        })
      );

      return { previousData, userId: data.userId };
    },
    onError: (_, __, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          getUserSurveyAnswersQueryKey(context.userId),
          context.previousData
        );
      }
    },
  });
};

export const useCreateDeviceMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async ({
      device,
      orgId,
    }: {
      device: NewDevice;
      orgId: string;
    }) => {
      const { data } = await postDevice(orgId, device);
      return await deviceSchema.validate(data);
    },
    onSuccess: (data, { orgId }) => {
      queryClient.setQueryData(getDevicesQueryKey(orgId), (old: Device[]) => [
        ...old,
        data,
      ]);
      navigate(
        fillRouteWithSlugs(ROUTES.DEVICE_OVERVIEW, {
          [ROUTE_SLUGS.DEVICE_ID]: data.id,
          [ROUTE_SLUGS.ORG_ID]: orgId,
        })
      );
    },
  });
};

export const useTaskFinishedMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      completed,
      tasks,
      templateId,
      deviceId,
      orgId,
    }: {
      completed: boolean;
      tasks: RoadmapTasks;
      templateId: TEMPLATE_TYPE;
      deviceId: string;
      orgId: string;
    }) => {
      return patchRoadmapTasks(orgId, deviceId, {
        ...tasks.tasks,
        [templateId]: completed,
      });
    },
    onMutate: ({ completed, deviceId, templateId }) => {
      queryClient.setQueryData(
        getTasksQueryKey(deviceId),
        (old: RoadmapTasks) => ({
          ...old,
          tasks: { ...old?.tasks, [templateId]: completed },
        })
      );
    },
    onSuccess: (_, { deviceId }) => {
      queryClient.invalidateQueries({ queryKey: getTasksQueryKey(deviceId) });
    },
  });
};

export const useCreateDocumentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: getCreateDocumentMutationKey(),
    mutationFn: async ({
      orgId,
      templateId,
      deviceId,
    }: {
      orgId: string;
      templateId: TEMPLATE_TYPE;
      deviceId: string;
    }) => {
      const config = ASSISTANT_CONFIG[templateId];
      if (!config) {
        throw new Error("Invalid templateId");
      }
      const { data } = await createDocument(orgId, deviceId, templateId);
      return DocumentSchema.validate(data);
    },
    onSuccess: async (data, { deviceId }) => {
      // Update the documents list local cache
      queryClient.setQueryData(
        getDocumentsQueryKey(deviceId),
        (old: Document[]): Document[] => {
          return [...old, data];
        }
      );

      queryClient.setQueryData(getDocumentQueryKey(deviceId, data.id), data);
    },
  });
};

export const useUploadFileMutation = () => {
  const queryClient = useQueryClient();
  // Todo: Caching the file
  return useMutation({
    mutationFn: async ({
      orgId,
      file,
      deviceId,
    }: {
      orgId: string;
      file: File;
      deviceId?: string;
    }) => {
      const { data } = await uploadFile({
        orgId,
        file: file && removeSpecialCharactersFomFilename(file),
        deviceId,
      });
      return FileSchema.validateSync(data);
    },
    onSuccess: (_, { deviceId }) => {
      queryClient.invalidateQueries({
        queryKey: getAllFilesInOrgQueryKey(deviceId),
      });
    },
  });
};

export const useSaveAnswerItemMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      data,
      answerId,
      orgId,
      deviceId,
      docId,
      versionId,
    }: {
      orgId: string;
      deviceId: string;
      documentVersion: DocumentVersion;
      docId: string;
      versionId: string;
      answerId: string;
      data: {
        type: string;
        fields: Fields;
      };
      step: TemplateElement;
      answerItems: AnswerItem[];
    }) => {
      const { data: updatedAnswer } = await postDocumentAnswerItem({
        orgId,
        deviceId,
        docId,
        versionId,
        answerId,
        data,
      });

      return DocumentAnswerItemSchema.validateSync(updatedAnswer);
    },
    onMutate: async ({
      documentVersion,
      step,
      answerItems,
      deviceId,
      docId,
    }) => {
      const previousDoc: Document | undefined = queryClient.getQueryData(
        getDocumentQueryKey(deviceId, docId)
      );
      const previousAnswer = documentVersion.answers.find(
        (a) => a.element === step.id
      );

      const newAnswer = {
        documentVersionId: documentVersion.id,
        id: previousAnswer?.id || "temp",
        element: step.id,
        answerItems,
        createdBy: previousAnswer?.createdBy || "temp",
        createdAt: previousAnswer?.createdAt || new Date(),
        updatedAt: new Date(),
      };

      const updatedDoc = {
        ...previousDoc,
        versions: previousDoc?.versions.map((version) => {
          if (version.id === documentVersion.id) {
            return {
              ...version,
              answers: [
                ...version.answers.filter(
                  (a: DocumentAnswer) => a.element !== step.id
                ),
                newAnswer,
              ],
            };
          } else {
            return version;
          }
        }),
      };

      queryClient.setQueryData(
        getDocumentQueryKey(deviceId, docId),
        updatedDoc
      );

      queryClient.setQueryData(
        getDocumentsQueryKey(deviceId),
        (old: Document[]) => [...old.filter((d) => d.id !== docId), updatedDoc]
      );
    },
    onError: (_, variables) => {
      const previousDoc: Document | undefined = queryClient.getQueryData(
        getDocumentQueryKey(variables.deviceId, variables.docId)
      );

      if (previousDoc) {
        queryClient.setQueryData(
          getDocumentQueryKey(previousDoc.deviceId, previousDoc.id),
          previousDoc
        );
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: getAnswerItemsQueryKey({
          orgId: variables.orgId,
          itemId: data.id,
          type: variables.data.type,
        }),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(variables.deviceId, variables.docId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentsQueryKey(variables.deviceId),
      });
    },
  });
};

export const usePatchAnswerItemMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      archived,
      data,
      answerId,
      itemId,
      orgId,
      docId,
      versionId,
      deviceId,
    }: {
      orgId: string;
      docId: string;
      versionId: string;
      deviceId: string;
      data: { fields: Fields };
      answerId: string;
      itemId: string;
      type: string;
      step: TemplateElement;
      answerItems: AnswerItem[];
      documentVersion: DocumentVersion;
      archived: boolean;
    }) => {
      const { data: updatedDocVersion } = await patchDocumentAnswerItem({
        orgId,
        deviceId,
        docId,
        versionId,
        answerId,
        itemId,
        data,
        archived,
      });
      return DocumentAnswerItemSchema.validateSync(updatedDocVersion);
    },
    onMutate: async ({
      documentVersion,
      step,
      answerItems,
      deviceId,
      docId,
    }) => {
      const previousDoc: Document | undefined = queryClient.getQueryData(
        getDocumentQueryKey(deviceId, docId)
      );
      const previousAnswer = documentVersion.answers.find(
        (a) => a.element === step.id
      );

      const newAnswer = {
        documentVersionId: documentVersion.id,
        id: previousAnswer?.id || "temp",
        element: step.id,
        answerItems: answerItems,
        createdBy: previousAnswer?.createdBy || "temp",
        createdAt: previousAnswer?.createdAt || new Date(),
        updatedAt: new Date(),
        answer: "",
      };

      const updatedDoc = {
        ...previousDoc,
        versions: previousDoc?.versions.map((version) => {
          if (version.id === documentVersion.id) {
            return {
              ...version,
              answers: [
                ...version.answers.filter(
                  (a: DocumentAnswer) => a.element !== step.id
                ),
                newAnswer,
              ],
            };
          } else {
            return version;
          }
        }),
      };

      queryClient.setQueryData(
        getDocumentQueryKey(deviceId, docId),
        updatedDoc
      );

      queryClient.setQueryData(
        getDocumentsQueryKey(deviceId),
        (old: Document[]) => [...old.filter((d) => d.id !== docId), updatedDoc]
      );
    },
    onError: (_, variables) => {
      const previousDoc: Document | undefined = queryClient.getQueryData(
        getDocumentQueryKey(variables.deviceId, variables.docId)
      );

      if (previousDoc) {
        queryClient.setQueryData(
          getDocumentQueryKey(previousDoc.deviceId, previousDoc.id),
          previousDoc
        );

        queryClient.setQueryData(
          getDocumentsQueryKey(variables.deviceId),
          (old: Document[]) => old
        );
      }
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getAnswerItemsQueryKey({
          orgId: variables.orgId,
          itemId: variables.itemId,
          type: variables.type,
        }),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(variables.deviceId, variables.docId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentsQueryKey(variables.deviceId),
      });
    },
  });
};

export const useSaveAnswerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      orgId,
      answer,
      step,
      documentVersion,
      answerFileId,
      deviceId,
    }: {
      orgId: string;
      docId: string;
      deviceId: string;
      step: TemplateElement;
      documentVersion: DocumentVersion;
      answerFileId?: string;
      answer?: string;
    }) => {
      const updatedDocVersion = await postDocumentAnswer(
        orgId,
        deviceId,
        documentVersion.documentId,
        documentVersion.id,
        {
          answer,
          element: step.id,
          answerFileId,
        }
      );
      return DocumentAnswerSchema.validateSync(updatedDocVersion.data);
    },
    onMutate: async ({
      docId,
      deviceId,
      answer,
      step,
      documentVersion,
      answerFileId,
    }) => {
      // cancel ongoing
      queryClient.cancelQueries({
        queryKey: getDocumentQueryKey(deviceId, docId),
      });

      queryClient.cancelQueries({
        queryKey: getDocumentsQueryKey(deviceId),
      });

      const previousDoc: Document | undefined = queryClient.getQueryData(
        getDocumentQueryKey(deviceId, docId)
      );
      const previousAnswer = documentVersion.answers.find(
        (a) => a.element === step.id
      );

      const newAnswer: DocumentAnswer = {
        documentVersionId: documentVersion.id,
        id: previousAnswer?.id || "temp",
        element: step.id,
        answer: answer,
        answerFileId: answerFileId,
        createdBy: previousAnswer?.createdBy || "temp",
        createdAt: previousAnswer?.createdAt || new Date(),
        updatedAt: new Date(),
      };

      const updatedDoc = {
        ...previousDoc,
        versions: previousDoc?.versions.map((version) => {
          if (version.id === documentVersion.id) {
            return {
              ...version,
              answers: [
                ...version.answers.filter(
                  (a: DocumentAnswer) => a.element !== step.id
                ),
                newAnswer,
              ],
            };
          } else {
            return version;
          }
        }),
      };

      queryClient.setQueryData(
        getDocumentQueryKey(deviceId, docId),
        updatedDoc
      );

      queryClient.setQueryData(
        getDocumentsQueryKey(deviceId),
        (old: Document[]) => [...old.filter((d) => d.id !== docId), updatedDoc]
      );
    },
  });
};

export const useUpdateUserMutation = (orgId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, user }: { id: string; user: Partial<User> }) =>
      patchUserInOrg(orgId, id, user),
    onMutate: ({ user }) => {
      queryClient.setQueryData(getUserQueryKey(), (old: User) => ({
        ...old,
        ...user,
      }));
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getOrganizationUsersQueryKey({ orgId }),
      });
      queryClient.invalidateQueries({
        queryKey: getUserQueryKey(),
      });
    },
  });
};

export const useUpdateOrgMutation = (orgId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ orgName }: { orgName: string }) =>
      updateOrg(orgId, { name: orgName }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getOrganizationUsersQueryKey({ orgId }),
      });
      queryClient.invalidateQueries({
        queryKey: getUserQueryKey(),
      });
    },
  });
};

export const useInviteUserMutation = (orgId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      inviteeEmail,
      inviteeFirstName,
      inviteeLastName,
    }: {
      inviteeEmail: string;
      inviteeFirstName: string;
      inviteeLastName: string;
    }) =>
      postUserInvite(orgId, {
        inviteeEmail,
        inviteeFirstName,
        inviteeLastName,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getUserQueryKey(),
      });
      queryClient.invalidateQueries({
        queryKey: getOrganizationUsersQueryKey({ orgId }),
      });
    },
  });
};

export const usePatchApproverStatusMutation = ({
  orgId,
  documentId,
  versionId,
  deviceId,
}: {
  orgId: string;
  documentId: string;
  versionId: string;
  deviceId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: getPatchApproverStatusMutationKey(),
    mutationFn: (data: {
      approverId: string;
      status: "USER_INVITE_SENT" | "AWAITING_SIGNATURE" | "APPROVED";
    }) =>
      patchApproverStatus({
        orgId,
        deviceId,
        documentId,
        versionId,
        status: data.status,
      }),
    onMutate: async (data) => {
      const previousData: DocumentVersionApprover[] | undefined =
        queryClient.getQueryData(getApproversQueryKey(documentId, versionId));

      if (previousData) {
        const nextData = previousData.map((approver) => {
          if (approver.id === data.approverId) {
            return { ...approver, approvalStatus: data.status };
          } else {
            return approver;
          }
        });

        // We update the local cache to reflect the new status
        queryClient.setQueryData(
          getApproversQueryKey(documentId, versionId),
          nextData
        );

        return { previousData };
      }
    },
    onError: (_, __, context) => {
      // If the mutation fails, we revert the local cache to the previous state
      if (context?.previousData) {
        queryClient.setQueryData(
          getApproversQueryKey(documentId, versionId),
          context.previousData
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getApproversQueryKey(documentId, versionId),
      });
      queryClient.invalidateQueries({
        queryKey: getUserTodosQueryKey(deviceId),
      });
    },
  });
};

export const usePostApproverMutation = ({
  orgId,
  documentId,
  versionId,
  deviceId,
}: {
  orgId: string;
  documentId: string;
  versionId: string;
  deviceId: string;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { userId: string }) =>
      postDocumentApprover({
        orgId,
        deviceId,
        documentId,
        versionId,
        data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getApproversQueryKey(documentId, versionId),
      }),
        queryClient.invalidateQueries({
          queryKey: getDocumentsQueryKey(deviceId),
        });
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(deviceId, documentId),
      });
    },
  });
};

export const useDeleteApproverMutation = ({
  orgId,
  documentId,
  versionId,
  deviceId,
}: {
  orgId: string;
  documentId: string;
  versionId: string;
  deviceId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { approverId: string }) =>
      deleteDocumentApprover(
        orgId,
        deviceId,
        documentId,
        versionId,
        data.approverId
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getApproversQueryKey(documentId, versionId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentsQueryKey(deviceId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(deviceId, documentId),
      });
    },
  });
};

export const useCreateDocumentVersionMutation = (
  orgId: string,
  deviceId: string
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ docId, file }: { docId: string; file?: File }) => {
      const { data } = await createDocumentVersion(
        orgId,
        deviceId,
        docId,
        file && removeSpecialCharactersFomFilename(file)
      );
      return DocumentVersionSchema.validateSync(data);
    },
    onSuccess: (data, { docId }) => {
      queryClient.setQueryData(
        getDocumentQueryKey(deviceId, docId),
        (old: Document | undefined) => {
          old?.versions.push(data);
          return {
            ...old,
            versions: orderBy(old?.versions, "createdAt", "desc"),
          };
        }
      );
      queryClient.setQueryData(
        getDocumentsQueryKey(deviceId),
        (old: Document[] | undefined) => {
          return old?.map((doc) => {
            if (doc.id === docId) {
              return {
                ...doc,
                versions: orderBy([data, ...doc.versions], "createdAt", "desc"),
              };
            } else {
              return doc;
            }
          });
        }
      );

      queryClient.invalidateQueries({
        queryKey: getUserTodosQueryKey(deviceId),
      });
    },
  });
};

export const useCheckoutMutation = () => {
  return useMutation({
    mutationFn: async ({
      orgId,
      plan,
      amount,
      cycle,
    }: {
      orgId: string;
      plan: Plan.PREMIUM | Plan.PREMIUM_PLUS;
      amount: number;
      cycle: BillingInterval;
    }) => {
      const { data } = await checkout(orgId, plan, amount, cycle);
      return data as string;
    },
  });
};

export const useCreateBillingSessionMutation = () => {
  return useMutation({
    mutationFn: async (orgId: string) => {
      const { data } = await postBillingSession(orgId);
      return data as string;
    },
  });
};

export const useDeleteFileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      orgId,
      fileId,
    }: {
      orgId: string;
      fileId: string;
      deviceId: string;
    }) => {
      return await deleteFile({ orgId, fileId });
    },
    onSuccess: (_, { fileId, deviceId }) => {
      // Invalidate the queries for all files in org
      queryClient.invalidateQueries({
        queryKey: getAllFilesInOrgQueryKey(deviceId),
      });

      // Remove the file from the file query cache
      queryClient.removeQueries({
        queryKey: getFileQueryKey(fileId),
      });

      queryClient.invalidateQueries({
        queryKey: getFileQueryKey(fileId),
      });
    },
  });
};

export const useUpdateUserUiStateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: UpdatableUiState) => {
      const { data } = await updateUserUiState(body);
      return FullUserUiStateSchema.validateSync(data);
    },
    onMutate: async (body: UpdatableUiState) => {
      // await queryClient.cancelQueries({ queryKey: getUserQueryKey() });

      const previousData: User | undefined =
        queryClient.getQueryData(getUserQueryKey());

      if (previousData) {
        queryClient.setQueryData(getUserQueryKey(), {
          ...previousData,
          userUiState: { ...previousData.userUiState, ...body },
        });
      }
    },
    // Not reverting state onError because we don't want continual popups if the mutation fails
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getUserQueryKey() });
    },
  });
};

export const useGeneratePdfMutation = () => {
  return useMutation({
    mutationFn: async ({
      orgId,
      deviceId,
      documentId,
      versionId,
      content,
      docName,
    }: {
      orgId: string;
      deviceId: string;
      documentId: string;
      versionId: string;
      content: string;
      docName: string;
    }) => {
      const response = await generatePdf({
        orgId,
        deviceId,
        documentId,
        versionId,
        content,
        docName,
      });

      // Convert arraybuffer to blob
      const blob = new Blob([response.data], { type: "application/pdf" });
      return blob;
    },
  });
};

export const usePostAssistantProcessMutation = () => {
  return useMutation({
    mutationFn: async ({
      data,
      orgId,
      deviceId,
    }: {
      data: { state: AssistantProcess["state"] };
      orgId: string;
      deviceId: string;
    }) => {
      const response = await postAssistantProcess({
        data,
        orgId,
        deviceId,
      });

      return response;
    },
  });
};

export const useUpdateAssistantProcessMutation = (
  orgId: string,
  deviceId: string
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      processId,
      body,
    }: {
      processId: string;
      body: PatchAssistantProcessBody;
    }) => {
      const { data } = await patchAssistantProcess(
        orgId,
        deviceId,
        processId,
        body
      );
      return validateAssistantProcess(data);
    },
    onMutate: async ({ processId, body }) => {
      // Cancel any outgoing refetches so they don't overwrite our optimistic update
      await queryClient.cancelQueries({
        queryKey: getAssistantProcessesQueryKey(deviceId),
      });

      // Snapshot the previous value
      const previousData: AssistantProcess[] | undefined =
        queryClient.getQueryData(getAssistantProcessesQueryKey(deviceId));

      queryClient.setQueryData(
        getAssistantProcessesQueryKey(deviceId),
        (old: AssistantProcess[] | undefined) => {
          return old?.map((process) =>
            process.id === processId ? { ...process, ...body } : process
          );
        }
      );

      return { previousData };
    },
    onError: (_, _1, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      if (context?.previousData) {
        queryClient.setQueryData(
          getAssistantProcessesQueryKey(deviceId),
          context.previousData
        );
      }
    },
  });
};

export const useSimpleUpdateAssistantProcessMutation = (
  orgId: string,
  deviceId: string
) => {
  return useMutation({
    mutationFn: async ({
      processId,
      body,
    }: {
      processId: string;
      body: PatchAssistantProcessBody;
    }) => {
      const { data } = await patchAssistantProcess(
        orgId,
        deviceId,
        processId,
        body
      );
      return validateAssistantProcess(data);
    },
  });
};

export const useUpdateAssistantProcessMutationDebounced = (
  orgId: string,
  deviceId: string
) => {
  const mutation = useSimpleUpdateAssistantProcessMutation(orgId, deviceId);

  return useDebounceQueryMutation<
    { processId: string; body: PatchAssistantProcessBody },
    AssistantProcess[],
    AssistantProcess
  >(mutation.mutateAsync, {
    delay: 1000,
    optimisticUpdate: {
      queryKey: getAssistantProcessesQueryKey(deviceId),
      updateFn: (oldData, variables) => {
        return oldData?.map((process) =>
          process.id === variables.processId
            ? { ...process, ...variables.body }
            : process
        );
      },
    },
  });
};

export const useDeleteDocumentVersionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      orgId,
      deviceId,
      docId,
      versionId,
    }: {
      orgId: string;
      deviceId: string;
      docId: string;
      versionId: string;
    }) => {
      await deleteDocumentVersion(orgId, deviceId, docId, versionId);
    },
    onMutate: async ({ deviceId, docId, versionId }) => {
      // remove the version from the document inside get getDocumentQueryKey and getDocumentsQueryKey
      const previousDocument: Document | undefined = queryClient.getQueryData(
        getDocumentQueryKey(deviceId, docId)
      );

      if (previousDocument) {
        queryClient.setQueryData(getDocumentQueryKey(deviceId, docId), {
          ...previousDocument,
          versions: previousDocument.versions.filter((v) => v.id !== versionId),
        });
      }

      const previousDocuments: Document[] | undefined =
        queryClient.getQueryData(getDocumentsQueryKey(deviceId));

      if (previousDocuments) {
        queryClient.setQueryData(
          getDocumentsQueryKey(deviceId),
          (old: Document[]) =>
            old.map((d) =>
              d.id === docId
                ? {
                    ...d,
                    versions: d.versions.filter((v) => v.id !== versionId),
                  }
                : d
            )
        );
      }

      return { previousDocument, previousDocuments };
    },
    onError: (_, { deviceId, docId }, context) => {
      if (context?.previousDocument) {
        queryClient.setQueryData(
          getDocumentQueryKey(deviceId, docId),
          context.previousDocument
        );
      }

      if (context?.previousDocuments) {
        queryClient.setQueryData(
          getDocumentsQueryKey(deviceId),
          context.previousDocuments
        );
      }
    },
    onSuccess: (_, { deviceId, docId }) => {
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(deviceId, docId),
      });

      queryClient.invalidateQueries({
        queryKey: getDocumentsQueryKey(deviceId),
        cancelRefetch: false,
      });

      queryClient.invalidateQueries({
        queryKey: getUserTodosQueryKey(deviceId),
      });
    },
  });
};
