import {
  ComponentConfigurationType,
  ComponentType,
  SubComponentType,
} from "@models";
import { CreateDemoDeviceRequest } from "@types";
import { prefilledDocumentsConfig } from "./prefilled-documents";

export const HARDWARE_DEMO_DEVICE: CreateDemoDeviceRequest = {
  deviceConfig: {
    name: "Hardware Demo - Stethio",
    description:
      "Stethio is a stethoscope to be used for listening to sounds in the chest cavity. Stethio is composed of stainless steel metal, rubber tubing, ear buds, and a high quality listening panel which allows amplification of sounds from the chest cavity to reach the ear buds. Stethio has a rubber grip on the stethosocpe handle.",
    isMDR: true,
    isFDA: false,
    isIVDR: false,
    components: [
      {
        name: "Stethio Stethosocope",
        type: ComponentType.HARDWARE,
        description:
          "Stethio is a stethoscope to be used for listening to sounds in the chest cavity. Stethio is composed of stainless steel metal, rubber tubing, ear buds, and a high quality listening panel which allows amplification of sounds from the chest cavity to reach the ear buds. Stethio has a rubber grip on the stethosocpe handle.",
        configuration: [
          {
            type: ComponentConfigurationType.HAS_HUMAN_CONTACT,
            value: "true",
          },
        ],
        subComponents: [
          {
            type: SubComponentType.MECHANICAL,
            description:
              "Stethio rubber ear buds connecting to a metal stethoscope body which is connected to rubber tubing which connects to the listening panel. The listening panel and the stainless steel handle are the only parts of the product that come in contact with the human body with intact skin. The listening panel contains a thin plastic film with an acoustic diaphram inside to allow for sound amplification.",
          },
        ],
      },
    ],
  },
  documentsConfig: prefilledDocumentsConfig,
};
