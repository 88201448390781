import { Typography } from "@mui/material";
import { TypeAnimation } from "react-type-animation";
import GenericButton from "src/components/GenericButton/GenericButton";
import { StepConfig } from "src/components/Onboarding/step-config";

const Start = ({
  step,
  handleNextStep,
}: {
  step: StepConfig;
  handleNextStep: () => void;
}) => {
  return (
    <div
      key={step.id}
      className="flex flex-col justify-center h-full gap-3 w-full z-10"
    >
      <div className="animate-fade-in">
        <Typography variant="h1" fontSize="3rem">
          FormlyAI
        </Typography>
      </div>
      <div className="animate-fade-in-delayed">
        <TypeAnimation
          sequence={[
            ...Array.from(step.subtitle ?? "")
              .map((_, index, arr) => [arr.slice(0, index + 1).join(""), 30])
              .flat(),
          ]}
          wrapper="div"
          speed={85}
          style={{
            fontSize: "1.6rem",
          }}
          cursor={true}
        />
      </div>
      <div className="animate-fade-in-delayed-button">
        <div className="mt-6">
          <GenericButton
            isDisabled={false}
            id="basic-button"
            variant="contained"
            onClick={handleNextStep}
            text="Let's start"
            color="primary"
          />
        </div>
      </div>
    </div>
  );
};

export default Start;
