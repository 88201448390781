import {
  useGetUser,
  useUpdateUserMutation,
  useUpdateUserSurveyAnswersMutation,
} from "@hooks";
import { UserSurveyAnswers } from "@models";
import { Chip, TextField, Typography } from "@mui/material";
import { captureSentryMessage } from "@utils";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "src/components/Onboarding/Loading";
import { LoomVideo } from "src/components/Onboarding/LoomVideo";
import Start from "src/components/Onboarding/Start";
import { steps } from "src/components/Onboarding/step-config";
import WelcomeSkeleton from "src/components/Onboarding/WelcomeSkeleton";
import { getOrgFromUser } from "src/utils/user";
import { RetroGrid } from "../AnimatedGridPattern";
import GenericButton from "../GenericButton/GenericButton";
import ProgressBar from "./ProgressBar";
import { checkIsDisabledStep } from "./utils";

export const Step = ({
  closeOnboarding,
  step,
  setStep,
  survey,
  isLoading,
}: {
  closeOnboarding: () => void;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  survey: UserSurveyAnswers | null | undefined;
  isLoading: boolean;
}) => {
  const { orgId = "" } = useParams();
  const { data: user } = useGetUser();
  const org = getOrgFromUser(user, orgId);

  const patchUserMutation = useUpdateUserMutation(orgId);

  const updateUserSurveyAnswersMutation = useUpdateUserSurveyAnswersMutation();

  const [shouldIgnoreUserNameStep, setShouldIgnoreUserNameStep] =
    useState(false);

  const handleNextStep = () => {
    // skip one step if user already has a name
    if (step === 0 && hasUserName) {
      setStep(step + 2);
      setShouldIgnoreUserNameStep(true);
      return;
    }

    setStep(step + 1);
  };

  const currentStep = steps[step];

  // Show a loading animation for 3 seconds before the last step
  // we need this because in the loading step there's actually no fetching
  // so we are mocking the loading state to suggest to the user that something is happening
  useEffect(() => {
    if (currentStep.id.includes("loading")) {
      const timer = setTimeout(() => {
        handleNextStep();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [step]);

  const isSurveyStep = currentStep.id.includes("survey");
  const isLastStep = step === steps.length - 1;

  const totalSurveySteps = steps.filter((s) => s.id.includes("survey")).length;
  const currentSurveyStep = isSurveyStep
    ? steps
        .filter((s) => s.id.includes("survey"))
        .findIndex((s) => s.id === currentStep.id) + 1
    : 0;

  if (!currentStep) {
    captureSentryMessage("Current step not found");
    return null;
  }

  const currentProgress = isSurveyStep
    ? ((currentSurveyStep - (shouldIgnoreUserNameStep ? 1 : 0)) /
        (totalSurveySteps - (shouldIgnoreUserNameStep ? 1 : 0))) *
      100
    : undefined;

  const [userName, setUserName] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
  });

  const handleSave = (userId: string) => {
    if (currentStep.id === "survey-name") {
      patchUserMutation.mutate({
        id: userId,
        user: { ...userName },
      });
    }

    if (isLastStep) {
      closeOnboarding();
      return;
    }

    handleNextStep();
  };

  const handleChange = (
    fieldId: string,
    v: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUserName({
      ...userName,
      [fieldId]: v.currentTarget.value,
    });
  };

  const handleChipClick = (
    userId: string,
    option: string,
    fieldId: keyof UserSurveyAnswers,
    multiple?: boolean
  ) => {
    if (!multiple) {
      updateUserSurveyAnswersMutation.mutate({
        data: {
          [fieldId]: option,
        },
        userId: userId,
      });
      return;
    }

    // Handle multiple selections
    const currentValue =
      survey && (survey[fieldId as keyof typeof survey] as string);
    const currentSelections = currentValue ? currentValue.split(",") : [];

    let newSelections: string[];
    if (currentSelections.includes(option)) {
      // Remove if already selected
      newSelections = currentSelections.filter((item) => item !== option);
    } else {
      // Add if not selected
      newSelections = [...currentSelections, option];
    }

    updateUserSurveyAnswersMutation.mutate({
      data: {
        [fieldId]: newSelections.join(","),
      },
      userId: userId,
    });
  };

  const isInitialStep = currentStep.id === "start";

  const nextButtonText = currentStep.buttonText || "Next";

  if (
    ((isLoading || survey === undefined) && !isInitialStep) ||
    !user ||
    !org
  ) {
    return <WelcomeSkeleton />;
  }

  const isNextDisabled = checkIsDisabledStep({
    currentStep,
    user: userName,
    survey,
    isSurveyStep,
  });

  const hasUserName = !!user.firstName && !!user.lastName;

  return (
    <div
      className={`flex flex-1 flex-col p-16 justify-center gap-12 transition-transform origin-center duration-500`}
    >
      {step === 0 && <RetroGrid />}
      <div
        key={currentStep.id}
        className="flex flex-1 flex-col justify-center gap-8"
      >
        {currentProgress !== undefined && (
          <ProgressBar progress={currentProgress} />
        )}
        {currentStep.title && (
          <div className="flex flex-col gap-3">
            <Typography variant={currentStep.titleLevel || "h6"}>
              {currentStep.title}
            </Typography>
            {currentStep.subtitle && (
              <Typography variant="body1" color="text.secondary">
                {currentStep.subtitle}
              </Typography>
            )}
          </div>
        )}

        {currentStep.inputFields?.map(
          ({ element, fieldId, label, options, multiple, hint }) => {
            switch (true) {
              case element === "input-group":
                return (
                  <TextField
                    key={fieldId}
                    id={fieldId}
                    label={label}
                    onChange={(v) => handleChange(fieldId, v)}
                    value={userName[fieldId as keyof typeof userName]}
                    className="w-2/5"
                  />
                );

              case element === "chip":
                if (options?.length === 0) {
                  captureSentryMessage("No options found for step field", {
                    step: currentStep.id,
                    field: fieldId,
                  });
                }

                let selectedValues: string[] = [];

                if (survey) {
                  selectedValues = survey[fieldId as keyof typeof survey]
                    ? (survey[fieldId as keyof typeof survey] as string).split(
                        ","
                      )
                    : [];
                }

                return (
                  <div key={fieldId}>
                    <div className="pb-4 flex gap-2 items-center">
                      <Typography variant="h6">{label}</Typography>
                      {hint && (
                        <Typography variant="body2" color="text.secondary">
                          {hint}
                        </Typography>
                      )}
                    </div>
                    <div className="flex gap-2 flex-wrap">
                      {options?.map(({ option }) => (
                        <Chip
                          key={option}
                          label={option}
                          color={
                            selectedValues.includes(option)
                              ? "primary"
                              : "default"
                          }
                          onClick={() =>
                            handleChipClick(
                              user.id,
                              option,
                              fieldId as keyof UserSurveyAnswers,
                              multiple
                            )
                          }
                        />
                      ))}
                    </div>
                  </div>
                );
            }
          }
        )}

        {currentStep.id === "loading" && <Loading />}

        {isInitialStep && (
          <Start step={currentStep} handleNextStep={handleNextStep} />
        )}

        {currentStep.id === "video" && (
          <LoomVideo src="https://www.loom.com/embed/852a60b8f78b4d37aaf03c9c12b20543?sid=51f14dfc-41d0-4428-9172-9f7faf611b9b&hideEmbedTopBar=true" />
        )}

        <div
          className={`p-0 ${currentStep.buttonAlign === "right" ? "self-end" : "self-start"}`}
        >
          {!currentStep.ignoreModalActions && (
            <GenericButton
              isDisabled={isNextDisabled}
              id="basic-button"
              variant="contained"
              onClick={() => handleSave(user.id)}
              text={nextButtonText}
              color="primary"
            />
          )}
        </div>
      </div>
    </div>
  );
};
