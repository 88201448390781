import { useGetUserSurveyAnswers } from "@hooks";
import { User } from "@models";
import { useEffect, useState } from "react";
import { Step } from "./Step";
import WelcomeModal from "./WelcomeModal";

const Welcome = ({
  user,
  closeModal,
}: {
  user: User;
  closeModal: () => void;
}) => {
  const { data: survey, isLoading } = useGetUserSurveyAnswers({
    userId: user.id,
  });
  const [step, setStep] = useState<number>(0);

  const getInitialStep = () => {
    const hasUserName = user.firstName && user.lastName;
    const isEmptyOrUndefined = (value?: string | null) =>
      !value || value === "";

    if (!survey) {
      return 0;
    }

    const hasAnyData =
      !isEmptyOrUndefined(survey.productTour) ||
      !isEmptyOrUndefined(survey.productStage) ||
      !isEmptyOrUndefined(survey.productCertification) ||
      !isEmptyOrUndefined(survey.productInterest);

    if (!hasAnyData) return 0;

    if (!hasUserName) return 1;

    const hasProductInfo =
      isEmptyOrUndefined(survey.productStage) ||
      isEmptyOrUndefined(survey.productCertification);
    if (hasProductInfo) {
      return 2;
    }

    const hasProductInterest =
      isEmptyOrUndefined(survey.productInterest) ||
      isEmptyOrUndefined(survey.productTour);
    if (hasProductInterest) {
      return 3;
    }

    return 5;
  };

  useEffect(() => {
    if (!isLoading && survey !== undefined) {
      setStep(getInitialStep());
    }
  }, [isLoading]);

  return (
    <WelcomeModal isOpen={true}>
      <Step
        step={step}
        setStep={setStep}
        survey={survey}
        isLoading={isLoading}
        closeOnboarding={closeModal}
      />
    </WelcomeModal>
  );
};

export default Welcome;
