import {
  usePatchAnswerItemMutation,
  useSaveAnswerItemMutation,
  useSaveAnswerMutation,
} from "@hooks";
import { DocumentAnswer, DocumentAnswerItem, DocumentVersion } from "@models";
import { AnswerItem, StepValue, TemplateElement } from "@types";
import { isEqual } from "lodash";
import { getAnswerFromDocumentVersion } from "src/components/Form/utils";

/**
 * Unifying answer saving to always have to same types when an answer is saved.
 */
export const useSaveAnswer = (orgId: string, deviceId: string) => {
  const saveAnswerMutation = useSaveAnswerMutation();
  const patchAnswerItemMutation = usePatchAnswerItemMutation();
  const saveAnswerItemMutation = useSaveAnswerItemMutation();

  const saveAnswer = async (
    docId: string,
    newStepValue: StepValue,
    step: TemplateElement,
    documentVersion: DocumentVersion
  ): Promise<
    DocumentAnswer | (DocumentAnswerItem | undefined)[] | undefined
  > => {
    const { answerFileId, answer } = newStepValue;
    const savedAnswer = getAnswerFromDocumentVersion(
      step.element,
      documentVersion
    );

    switch (step.element.type) {
      case "fileUpload":
        const hasFileIdChanged = answerFileId !== savedAnswer?.answerFileId;
        if (typeof answerFileId !== "string")
          throw new Error("File ID is not a string");
        if (hasFileIdChanged) {
          return saveAnswerMutation.mutateAsync({
            orgId,
            step,
            documentVersion,
            deviceId,
            docId,
            answerFileId,
          });
        }
        break;

      case "answerItemsElement":
        const parsedAnswerItems = newStepValue?.answerItems?.filter(
          (answerItem: AnswerItem) =>
            Object.values(answerItem.fields).every(
              (field) => field !== "" || field !== null
            )
        );

        if (!parsedAnswerItems) return;

        const versionAnswer = documentVersion.answers.find(
          (a) => a.element === step.id
        );

        if (!versionAnswer) return;
        const answerItems = await Promise.all(
          parsedAnswerItems.map(async (answerItem: AnswerItem) => {
            const savedAnswerItem = versionAnswer.answerItems?.find(
              (savedAnswerItem) => savedAnswerItem.id === answerItem.id
            );

            // If there's been an update to an existing answer item, patch it
            if (
              savedAnswerItem &&
              (savedAnswerItem.archived !== answerItem.archived ||
                !isEqual(savedAnswerItem.fields, answerItem.fields))
            ) {
              return patchAnswerItemMutation.mutateAsync({
                orgId,
                versionId: documentVersion.id,
                deviceId,
                docId: docId,
                archived: answerItem.archived,
                data: { fields: answerItem.fields },
                itemId: answerItem.id,
                type: answerItem.type,
                answerId: versionAnswer.id,
                step,
                answerItems: parsedAnswerItems,
                documentVersion,
              });
            }

            // If the answer item is new, save it
            if (answerItem.createdBy === "temp") {
              return saveAnswerItemMutation.mutateAsync({
                orgId,
                versionId: documentVersion.id,
                deviceId,
                docId: docId,
                answerId: versionAnswer.id,
                data: {
                  type: answerItem.type,
                  fields: answerItem.fields,
                },
                step,
                documentVersion,
                answerItems: parsedAnswerItems,
              });
            }
          })
        );
        return answerItems;

      default:
        const noSavedAnswer = savedAnswer === undefined;
        const hasAnswerChanged = answer !== savedAnswer?.answer;
        if (noSavedAnswer || hasAnswerChanged) {
          return saveAnswerMutation.mutateAsync({
            orgId,
            step,
            documentVersion,
            deviceId,
            docId,
            answer,
          });
        }
    }

    return Promise.resolve(undefined);
  };

  return { saveAnswer };
};
