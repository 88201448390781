import { AssistantProcess } from "@models";
import { TemplateElement } from "@types";

export const hasInlineSuggestion = (step: TemplateElement) =>
  (step.prompt && ["table"].includes(step.element.type)) || false;

export const hasGenericSuggestion = (
  step: TemplateElement,
  assistantProcess?: AssistantProcess
): boolean =>
  !!assistantProcess &&
  ["textField", "table", "select"].includes(step.element.type) &&
  !step.prompt &&
  !step.element.transformerConfig;
