import { Skeleton } from "@mui/material";

export const DocumentUpdateSkeleton = () => {
  return (
    <div className="flex flex-1 flex-col gap-y-6">
      <div className="flex flex-col gap-y-3">
        <div className="flex items-center gap-2">
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="circular" width={24} height={24} />
        </div>
        <Skeleton variant="text" width={"80%"} height={12} />
      </div>
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col gap-y-4">
          <Skeleton variant="text" width={400} height={24} />
          <Skeleton variant="text" width={"100%"} height={40} />
          <div className="flex flex-col gap-y-4"></div>
          <Skeleton variant="text" width={400} height={24} />
          <Skeleton variant="text" width={"100%"} height={100} />
        </div>
      </div>
    </div>
  );
};
