import { Typography } from "@mui/material";
import React, { useCallback } from "react";
import AccordionList from "src/components/Form/Elements/AccordionList/AccordionList";
import AccordionListNoAPICalls from "src/components/Form/Elements/AccordionList/AccordionListNoAPICalls";
import DateInput from "src/components/Form/Elements/Date";
import FileUpload from "src/components/Form/Elements/FileUpload";
import ParsedFileUpload from "src/components/Form/Elements/ParsedFileUpload";
import Select from "src/components/Form/Elements/Select";
import TextField from "src/components/Form/Elements/TextField";
import { StepInputControls } from "src/components/FormStep/StepInputControls";
import { useSnackbar } from "..";
import { DocumentVersion, TEMPLATE_TYPE } from "../../stores/models";
import { StepValue, Suggestion, TemplateElement } from "../../types";
import { Table } from "./Elements/Table";
import { handleCSVUpload } from "./utils";

type Props = {
  stepValue: StepValue;
  hasInlineSuggestion?: boolean;
  disabled?: boolean;
  onChange?: (step: TemplateElement, value: StepValue) => void;
  onRegenerateSuggestion?: () => void;
  onReRunTransformer?: () => void;
  step: TemplateElement;
  suggestion?: Suggestion;
  onUndo?: () => void;
  onRedo?: () => void;
  documentVersion?: DocumentVersion;
  templateId: TEMPLATE_TYPE;
  title?: string;
  textFieldReadOnly?: boolean;
};

export const ElementMapper: React.FC<Props> = ({
  disabled,
  hasInlineSuggestion = false,
  onChange,
  onRegenerateSuggestion,
  onReRunTransformer,
  onUndo,
  onRedo,
  step,
  suggestion,
  stepValue,
  documentVersion,
  templateId,
  title = "Answer",
  textFieldReadOnly = false,
}) => {
  const { showSnackbar, hideSnackbar } = useSnackbar();
  const { element } = step;

  const hasTransformer = !!element.transformerConfig;
  const isFetchingSuggestion = suggestion?.loading || false;
  const hasActions =
    !!onChange ||
    !!onRegenerateSuggestion ||
    !!onReRunTransformer ||
    !!onUndo ||
    !!onRedo;

  if (hasActions && element.type === "table") {
    if (!onUndo || !onRedo)
      throw new Error("Undo and Redo functions not sent to ElementMapper.");
  }

  const renderElement = () => {
    switch (element.type) {
      case "fileUpload":
        return (
          <FileUpload
            element={element}
            answerFileId={stepValue.answerFileId}
            onChange={(_, value) => onChange?.(step, value)}
          />
        );

      case "parsedFileUploadElement":
        return (
          <ParsedFileUpload
            element={element}
            value={stepValue.answer || ""}
            onChange={(_, value) => onChange?.(step, value)}
          />
        );

      case "table":
        return (
          <Table
            key={element.id}
            ref={tableRef}
            element={element}
            value={stepValue.answer || ""}
            {...(onChange && {
              onChange: (_, value) => onChange?.(step, value),
            })}
            isFetchingSuggestion={isFetchingSuggestion}
          />
        );

      case "textField":
        return (
          <TextField
            disabled={disabled}
            onChange={(_, value) => onChange?.(step, value)}
            element={element}
            value={stepValue.answer || ""}
            textFieldReadOnly={textFieldReadOnly}
          />
        );

      case "select":
        return (
          <Select
            disabled={disabled}
            onChange={(_, value) => onChange?.(step, value)}
            value={stepValue.answer || ""}
            element={element}
          />
        );

      case "answerItemsElement":
        return documentVersion !== undefined ? (
          <AccordionList
            disabled={disabled}
            onChange={(value) => onChange?.(step, value)}
            answerItems={stepValue.answerItems || []}
            step={step}
            element={element}
            documentVersion={documentVersion}
          />
        ) : (
          <AccordionListNoAPICalls
            disabled={disabled}
            onChange={(value) => onChange?.(step, value)}
            answerItems={stepValue.answerItems || []}
            element={element}
          />
        );

      case "dateInput":
        return (
          <DateInput
            style={element.style}
            hideLabel={element.options.hideLabel}
            disabled={disabled}
            onChange={(value) => onChange?.(step, value)}
            value={stepValue.answer || ""}
          />
        );

      default:
        return null;
    }
  };

  const importAnswer = async (file: File) => {
    try {
      hideSnackbar();
      const importedAnswer = await handleCSVUpload(file, templateId, step.id);
      onChange?.(step, { answer: importedAnswer });
    } catch (error: any) {
      showSnackbar(error.message || "Please upload a valid CSV file.");
    }
  };

  const handleCopyTable = useCallback(() => {
    if (element.type === "table" && tableRef.current) {
      tableRef.current.copyTable();
    }
  }, [element.type]);

  const tableRef = React.useRef<{ copyTable: () => void } | null>(null);

  return (
    <div className="flex flex-col">
      {/* Min height is to prevent the text from shifting when the controls are hidden */}
      <div className="flex items-center justify-between gap-x-3 min-h-[34px]">
        <Typography
          variant="body1"
          className={`${!hasInlineSuggestion && "leading-[34px]"} font-bold`}
        >
          {title}
        </Typography>
        {hasActions ? (
          <StepInputControls
            hasCSVUpload={element.type === "table"}
            hasInlineSuggestion={hasInlineSuggestion}
            hasTransformer={hasTransformer}
            onRegenerateSuggestion={onRegenerateSuggestion}
            onReRunTransformer={onReRunTransformer}
            onCSVUpload={(file: File) => importAnswer(file)}
            onUndo={element.type === "table" ? onUndo : undefined}
            onRedo={element.type === "table" ? onRedo : undefined}
            onCopyTable={element.type === "table" ? handleCopyTable : undefined}
          />
        ) : null}
      </div>
      {renderElement()}
    </div>
  );
};
