import { Plan } from "@models";

export type PlanConfig = {
  name: string;
  stripeId?: string;
  id: Plan;
  monthlyPrice: number;
  yearlyPrice: number;
  shortDescription: string;
  features: (string | { text: string; included: boolean })[];
  popular?: boolean;
};

export const plans: PlanConfig[] = [
  {
    name: "Free",
    id: Plan.FREE,
    monthlyPrice: 0,
    yearlyPrice: 0,
    shortDescription: "Try out our platform with limited features.",
    features: [
      "Free roadmap. 10+ documents included.",
      {
        text: "All technical documentation needed for a CE mark - ISO 14971, IEC 62304, IEC 62366 and more",
        included: false,
      },
      {
        text: "Quality Management System compliant with ISO 13485",
        included: true,
      },
      {
        text: "Streamlined document approval and lifecycle management with the built in eQMS",
        included: false,
      },
      {
        text: "One-click technical file generation ready for notified body submission",
        included: false,
      },
      {
        text: "Reliably autofill your documents with our fine-tuned AI",
        included: false,
      },
      {
        text: "Your documents are yours. Export them at any time",
        included: true,
      },
      {
        text: "Documentation checked by our CE Audit experts",
        included: false,
      },
      {
        text: "Weekly 1:1 with our regulatory experts for guidance and progress validation",
        included: false,
      },
    ],
  },
  {
    name: "Basic",
    id: Plan.PREMIUM,
    stripeId: window._env_.PREMIUM_STRIPE_PRODUCT_ID,
    monthlyPrice: 499,
    yearlyPrice: 399,
    shortDescription: "Everything you need to certify your device.",
    features: [
      "Start to finish CE Mark: 1 Device",
      "All technical documentation needed for a CE mark - ISO 14971, IEC 62304, IEC 62366 and more",
      "Quality Management System compliant with ISO 13485",
      "Streamlined document approval and lifecycle management with the built in eQMS",
      "One-click technical file generation ready for notified body submission",
      "Reliably autofill your documents with our fine-tuned AI",
      "Your documents are yours. Export them at any time",
      {
        text: "Documentation checked by our CE Audit experts",
        included: false,
      },
      {
        text: "Weekly 1:1 with our regulatory experts for guidance and progress validation",
        included: false,
      },
    ],
  },
  {
    name: "Premium",
    id: Plan.PREMIUM_PLUS,
    stripeId: window._env_.PREMIUM_PLUS_STRIPE_PRODUCT_ID,
    monthlyPrice: 899,
    yearlyPrice: 699,
    popular: true,
    shortDescription: "Basic, plus regulatory expert assistance.",
    features: [
      "Start to finish CE Mark: 1 Device",
      "All technical documentation needed for a CE mark - ISO 14971, IEC 62304, IEC 62366 and more",
      "Quality Management System compliant with ISO 13485",
      "Streamlined document approval and lifecycle management with the built in eQMS",
      "One-click technical file generation ready for notified body submission",
      "Reliably autofill your documents with our fine-tuned AI",
      "Your documents are yours. Export them at any time",
      "Documentation checked by our CE Audit experts",
      "Weekly 1:1 with our regulatory experts for guidance and progress validation",
    ],
  },
];
