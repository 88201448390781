import { ASSISTANT_CONFIG } from "@config";
import {
  useGetAssistantProcesses,
  useGetDevice,
  useGetDocuments,
  useUpdateAssistantProcessMutation,
} from "@hooks";
import { AssistantProcessStatus, TEMPLATE_TYPE } from "@models";
import { Alert, AlertTitle, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmationModal } from "src/components/ConfirmationModal";
import { ProgressBubbleLine } from "src/components/Form/ProgressBubbleLine";
import { AssistantMultiStepFormStep } from "src/components/FormStep/AssistantMultiStepFormStep";
import { AssistantMultiStepFormTopBar } from "src/components/WizardTopBar/AssistantMultiStepFormTopBar";
import { config } from "src/config/assistant";
import { ROUTE_SLUGS, ROUTES } from "src/config/navigation/routes";
import { Loading } from "src/pages";
import { fillRouteWithSlugs } from "src/utils/navigation";
import { isStepComplete } from "src/utils/step";

/**
 * Collection of user updates in an AssistantProcess.
 * Nothing is created in the MultiStepForm part of the process, just answers collected to be updated and created after.
 */
export const DU_MultiStepForm = () => {
  const { orgId = "", deviceId = "" } = useParams();
  const navigate = useNavigate();

  const { data: assistantProcesses } = useGetAssistantProcesses({
    orgId,
    deviceId,
    status: AssistantProcessStatus.IN_PROGRESS,
  });

  // const { mutate: updateAssistantProcessDebounced } =
  //   useUpdateAssistantProcessMutationDebounced(orgId, deviceId);

  const { mutate: updateAssistantProcess } = useUpdateAssistantProcessMutation(
    orgId,
    deviceId
  );

  const { data: device } = useGetDevice({
    orgId,
    deviceId,
  });
  const { data: documents } = useGetDocuments(orgId, deviceId);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const assistantProcess = assistantProcesses?.[0];

  // Get assistant process info
  const deviceDocumentChangeList =
    assistantProcess?.state.dataKeysToUpdate || [];
  const answers = assistantProcess?.state.changes || [];
  const [activeStepIdx, setActiveStepIdx] = useState<number>(0);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (assistantProcess && !isInitialized) {
      setActiveStepIdx(answers.length > 0 ? answers.length - 1 : 0);
      setIsInitialized(true);
    }
  }, [assistantProcess]);

  // Get step data
  // const activeTemplateElementId: DocumentDataKey | undefined =
  //   deviceDocumentChangeList?.[activeStepIdx]?.dataKeyId as DocumentDataKey;

  // const answerSetupComplete = assistantProcess?.state.changes.find(
  //   (change) => change.dataKeyId === activeTemplateElementId
  // );

  const activeTemplateId: TEMPLATE_TYPE | undefined =
    deviceDocumentChangeList?.[activeStepIdx]?.templateId as TEMPLATE_TYPE;
  const activeTemplateDocName = config[activeTemplateId]?.docName;
  const activeTemplateDocType = config[activeTemplateId]?.docType;
  const activeDocumentVersion =
    activeTemplateDocType === "RCD"
      ? undefined
      : documents?.find((doc) => doc.name === activeTemplateId)?.versions[0];
  const activeAnswer = answers[activeStepIdx];
  const steps = deviceDocumentChangeList
    ?.map(({ templateId, dataKeyId }) =>
      ASSISTANT_CONFIG[templateId as TEMPLATE_TYPE].elements.find(
        (e) => e.id === dataKeyId
      )
    )
    .filter((step) => !!step);
  const activeTemplateElement = steps[activeStepIdx];
  // const activeElementHasNoPrompt = !activeTemplateElement?.prompt;
  // This component is loading before the answers array is populated
  // const isLoading =
  //   isLoadingAssistantProcess || isLoadingDevice || isLoadingDocuments;
  // (activeAnswer === undefined && !answerSetupComplete);

  const isReadyForNextStep = useMemo(
    () =>
      activeAnswer?.acceptedAnswer
        ? isStepComplete(activeTemplateElement, activeAnswer.acceptedAnswer)
        : false,
    [activeTemplateElement, activeAnswer?.acceptedAnswer]
  );

  // Set up the next question/answer in the state to show the user
  // useEffect(() => {
  //   // Make sure we have data
  //   if (activeTemplateElement && documents && answers) {
  //     if (
  //       answers.length === activeStepIdx &&
  //       assistantProcess?.id &&
  //       !answerSetupComplete
  //     ) {
  //       const previousAnswer = getAnswer(
  //         {
  //           answer: undefined,
  //           answerFileId: undefined,
  //           answerItems: undefined,
  //         },
  //         activeTemplateElement,
  //         activeDocumentVersion,
  //         documents
  //       );

  //       updateAssistantProcess({
  //         processId: assistantProcess.id,
  //         body: {
  //           state: {
  //             ...assistantProcess.state,
  //             currentPath: `${ROUTES.ASSISTANT_DU_MULTI_STEP_FORM}`,
  //             changes: [
  //               ...answers,
  //               {
  //                 dataKeyId: activeTemplateElementId,
  //                 templateId: activeTemplateId,
  //                 previousAnswer: previousAnswer,
  //                 // By default the previous answer can be kept so a user can just click next
  //                 ...(activeElementHasNoPrompt &&
  //                   previousAnswer && {
  //                     acceptedAnswer: previousAnswer,
  //                   }),
  //               },
  //             ],
  //           },
  //         },
  //       });
  //     }
  //   }
  // }, [
  //   activeTemplateElementId,
  //   activeTemplateId,
  //   activeTemplateElement,
  //   activeDocumentVersion,
  //   documents,
  //   assistantProcess?.id,
  //   assistantProcess?.state,
  // ]);

  // ---- App Actions ----

  const endChangeManagement = () => {
    if (!assistantProcess) return;
    updateAssistantProcess({
      processId: assistantProcess.id,
      body: {
        status: AssistantProcessStatus.CANCELLED,
      },
    });
    navigate(
      fillRouteWithSlugs(ROUTES.DEVICE_OVERVIEW, {
        [ROUTE_SLUGS.ORG_ID]: orgId,
        [ROUTE_SLUGS.DEVICE_ID]: deviceId,
      })
    );
  };

  // const handleCurrentAnswerChange = (
  //   _: TemplateElement,
  //   value: AssistantMultiStepFormAnswerUpdate
  // ) => {
  //   if (!assistantProcess) return;

  //   const answersCopy = [...answers];
  //   answersCopy[activeStepIdx] = {
  //     ...answersCopy[activeStepIdx],
  //     ...value,
  //   };

  //   updateAssistantProcessDebounced({
  //     processId: assistantProcess.id,
  //     body: {
  //       state: {
  //         ...assistantProcess.state,
  //         changes: answersCopy,
  //       },
  //     },
  //   });
  // };

  const handleGoToNextStep = () => {
    if (activeStepIdx < deviceDocumentChangeList.length - 1) {
      setActiveStepIdx(activeStepIdx + 1);
    } else if (activeStepIdx === deviceDocumentChangeList.length - 1) {
      navigate(
        fillRouteWithSlugs(ROUTES.ASSISTANT_DU_UPDATE_DOCS, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
          [ROUTE_SLUGS.DEVICE_ID]: deviceId,
        })
      );
    }
  };

  const handleGoToPreviousStep = () => {
    if (activeStepIdx > 0) {
      setActiveStepIdx(activeStepIdx - 1);
    }
  };

  const isRcd = activeTemplateDocType === "RCD";

  if (!documents || !device || !assistantProcess) {
    return <Loading />;
  }

  return (
    <div className="flex flex-1 flex-col gap-y-2">
      <AssistantMultiStepFormTopBar
        activeStep={activeTemplateElement}
        steps={steps}
        nextStep={handleGoToNextStep}
        prevStep={handleGoToPreviousStep}
        endButtonOnClick={() => setOpenConfirmationModal(true)}
        isReadyForNextStep={isReadyForNextStep}
      />
      <div className={`flex flex-col`}>
        <Typography variant="body1">
          {`Document: ${activeTemplateDocName}`}
        </Typography>
        {/* <FormPageTitle title={`Assistant  Update Documents`} /> */}
        <ProgressBubbleLine
          className="w-[400px]"
          totalItemCount={deviceDocumentChangeList.length}
          currentItemIndex={activeStepIdx}
        />
      </div>
      <div className="flex flex-col">
        {activeTemplateElement && (activeDocumentVersion || isRcd) ? (
          <AssistantMultiStepFormStep
            templateId={activeTemplateId}
            documentVersion={activeDocumentVersion}
            key={activeTemplateElement.id}
            step={activeTemplateElement}
            nextStep={handleGoToNextStep}
            // answer={activeAnswer}
            // handleAnswerChange={handleCurrentAnswerChange}
            documents={documents}
            device={device}
            assistantProcess={assistantProcess}
            //isReadyForNextStep={isReadyForNextStep}
          />
        ) : (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Missing necessary document data. Have you started the document?
          </Alert>
        )}
      </div>
      <ConfirmationModal
        title="End Change Management"
        confirmText="Confirm"
        content="Are you sure you want to end change management? All progress made will be erased."
        onAbort={() => setOpenConfirmationModal(false)}
        onConfirm={endChangeManagement}
        open={openConfirmationModal}
      />
    </div>
  );
};
