import {
  Device,
  Document,
  DocumentAnswer,
  DocumentVersion,
  TEMPLATE_TYPE,
} from "@models";
import { AnswerItem, TemplateElement } from "@types";
import {
  getDocumentStepsFromConfig,
  getFilteredElements,
} from "src/utils/documents";

export const isAnswerItemsComplete = (
  answerItems: AnswerItem[],
  step: TemplateElement
): boolean => {
  return answerItems.every((item) => {
    // Archived items are considered complete
    if (item.archived) {
      return true;
    }

    if (step.element.type !== "answerItemsElement") {
      throw new Error("Step is not an answerItemsElement");
    }

    // Editing items are used in AccordionListNoAPICalls and means a new item hasn't
    // been committed to save yet.
    if (item.createdBy === "editing") {
      return false;
    }

    return step.element.options.fields.every((field) => {
      const fieldValue = item.fields[field.fieldKey];

      if (!field.required) {
        return true;
      }

      if (fieldValue === undefined) {
        return false;
      }

      if (typeof fieldValue === "string" && fieldValue.trim() === "") {
        return false;
      }

      return true;
    });
  });
};

export const isStepComplete = (
  step: TemplateElement,
  answer: Partial<DocumentAnswer>
): boolean => {
  if (!step.required) {
    return true;
  }

  if (step.element.type === "fileUpload") {
    return !!answer?.answerFileId;
  }

  if (step.element.type === "answerItemsElement") {
    const answerItems = answer.answerItems;
    if (!Array.isArray(answerItems)) {
      return false;
    }

    return isAnswerItemsComplete(answerItems, step);
  }

  // For all other element types, check for non-empty answer or default value
  const hasNonEmptyAnswer = !!answer?.answer && answer.answer.trim() !== "";
  const hasDefaultValue = !!step.element.options?.default;

  // console.log("hasNonEmptyAnswer", hasNonEmptyAnswer, hasDefaultValue);

  return hasNonEmptyAnswer || hasDefaultValue;
};

export const allStepsCompleted = (
  document: Document,
  documentVersion: DocumentVersion,
  device: Device,
  documents: Document[]
) => {
  const steps = getDocumentStepsFromConfig(document.name as TEMPLATE_TYPE);

  const filteredSteps = getFilteredElements(steps, device, documents);

  return filteredSteps.every((step) => {
    const stepAnswer = documentVersion.answers.find(
      (a) => a.element === step.id
    );

    return isStepComplete(step, stepAnswer || {});
  });
};
