import { Dialog } from "@mui/material";

const WelcomeModal = ({
  children,

  isOpen = false,
}: {
  children: React.ReactNode;
  isOpen: boolean;
}) => {
  return (
    <Dialog
      className="rounded"
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "1200px",
          minWidth: "40%",
          width: "70%",
          height: "70%",
          maxHeight: 700,
        },
      }}
    >
      {children}
    </Dialog>
  );
};

export default WelcomeModal;
