import * as Sentry from "@sentry/react";
import { Extras } from "@sentry/types";

export const captureSentryException = (exception: any, extras?: Extras) => {
  Sentry.captureException(exception, { extra: extras });
};

export const captureSentryMessage = (message: string, extras?: Extras) => {
  Sentry.captureMessage(message, { extra: extras });
};
