import { theme } from "@config";
import { AddCircleOutlineOutlined, ChevronRight } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Button,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";
import { AnswerItem, AnswerItemsElement, StepValue } from "@types";
import { scrollToElement } from "@utils";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AccordionListMapper from "src/components/Form/Elements/AccordionList/AccordionListMapper";
import {
  getAlwaysShownValues,
  getHighestId,
  isItemComplete,
} from "src/components/Form/Elements/AccordionList/utils";
import ListMenu from "./AccordionListMenu";

type Props = {
  element: AnswerItemsElement;
  answerItems: AnswerItem[];
  disabled?: boolean;
  onChange: (value: StepValue) => void;
};

export const AccordionListNoAPICalls: React.FC<Props> = ({
  disabled,
  element,
  answerItems,
  onChange,
}) => {
  const { orgId = "" } = useParams<{
    orgId: string;
  }>();

  const [expandedId, setExpandedId] = useState<string | null>(null);

  const handleChange = ({
    accordionIdx,
    fieldKey,
    value,
  }: {
    accordionIdx: number;
    fieldKey: string;
    value: StepValue | undefined;
  }) => {
    const updatedList = [...answerItems];
    updatedList[accordionIdx] = {
      ...updatedList[accordionIdx],
      fields: {
        ...updatedList[accordionIdx].fields,
        [fieldKey]: value?.answer ?? "",
      },
    };

    onChange({ answerItems: updatedList });
  };

  const addNewItem = () => {
    const nextId = getHighestId(answerItems) + 1;
    const emptyFields = element.options.fields.reduce((acc, { fieldKey }) => {
      return {
        ...acc,
        [fieldKey]: "",
      };
    }, {});

    const newList = [
      ...answerItems,
      {
        type: element.options.typeId,
        sequentialTypeNumber: nextId,
        id: crypto.randomUUID(),
        fields: emptyFields,
        updatedAt: new Date(),
        answerId: "temp",
        organizationId: orgId,
        createdBy: "editing",
        createdAt: new Date(),
        archived: false,
      },
    ];

    const fieldId = `${element.options.typeId}${nextId}`;

    setExpandedId(fieldId);
    onChange({ answerItems: newList });
    scrollToElement(fieldId);
  };

  const commitChanges = async () => {
    onChange({
      answerItems: answerItems.map((item) => ({
        ...item,
        createdBy: item.createdBy === "editing" ? "temp" : item.createdBy,
      })),
    });
  };

  return (
    <div key={element.id} className="flex flex-col gap-y-3">
      {answerItems.map((accordion, accordionIdx) => {
        const fieldType = accordion.type;
        const fieldNumber = accordion.sequentialTypeNumber;
        const fieldId = `${fieldType}${fieldNumber}`;
        const itemEditing = accordion.createdBy === "editing";
        const itemWillBeSaved = accordion.createdBy === "temp";
        const itemIsInTempStorage = itemWillBeSaved || itemEditing;

        if (accordion.archived) {
          return null;
        }

        return (
          <div key={fieldId} id={fieldId} className="flex items-start">
            <Accordion
              expanded={expandedId === fieldId}
              onChange={(_, isExpanded) =>
                setExpandedId(isExpanded && fieldId ? fieldId : null)
              }
              style={{
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <AccordionSummary>
                <div className="flex justify-between items-center w-full">
                  <div>
                    <Typography id="title">
                      #{fieldId} - {String(accordion.fields?.name || "Name")}
                      {itemIsInTempStorage && (
                        <Chip
                          label="new"
                          color="info"
                          size="small"
                          className="ml-2"
                          variant="outlined"
                        />
                      )}
                    </Typography>
                    <div className="flex flex-wrap gap-3">
                      <Typography
                        color="text.secondary"
                        sx={{
                          fontSize: "12px",
                          display: expandedId === fieldId ? "none" : "block",
                        }}
                      >
                        {getAlwaysShownValues(accordion, element)}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex items-center">
                    {itemEditing && (
                      <Button
                        onClick={commitChanges}
                        variant="contained"
                        color="primary"
                        disabled={!isItemComplete(accordion, element)}
                        className="mr-2"
                      >
                        Create
                      </Button>
                    )}
                    <ListMenu
                      fieldId={fieldId}
                      accordionIdx={accordionIdx}
                      list={answerItems}
                      patchItem={({ answer }: { answer: AnswerItem }) => {
                        const answerIsInTempStorage =
                          answer.createdBy === "editing" ||
                          answer.createdBy === "temp";

                        // Delete the item if its archived and doesn't exist
                        if (answerIsInTempStorage && answer.archived) {
                          onChange({
                            answerItems: answerItems.filter(
                              (item) => item.id !== answer.id
                            ),
                          });
                        } else {
                          onChange({
                            answerItems: answerItems.map((item) =>
                              item.id === answer.id
                                ? { ...item, ...answer }
                                : item
                            ),
                          });
                        }
                      }}
                      onChange={() => {}}
                    />
                    <IconButton>
                      <ChevronRight
                        sx={{
                          transform:
                            expandedId === fieldId ? "rotate(90deg)" : "none",
                          transition: "transform 0.2s",
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
              </AccordionSummary>
              <div className="flex flex-wrap gap-3 w-full p-4 pt-0">
                <AccordionListMapper
                  accordionIdx={accordionIdx}
                  element={element}
                  accordion={answerItems[accordionIdx]}
                  disabled={disabled}
                  onChange={({ fieldKey, value }) =>
                    handleChange({ accordionIdx, fieldKey, value })
                  }
                />
              </div>
            </Accordion>
          </div>
        );
      })}
      <Button onClick={addNewItem}>
        <AddCircleOutlineOutlined className="mr-1" /> Add new item
      </Button>
    </div>
  );
};

export default AccordionListNoAPICalls;
