import { Chip } from "@components";
import { ASSISTANT_CONFIG, ROUTES } from "@config";
import {
  useDocEngine,
  useGetAssistantProcesses,
  useGetDevice,
  useGetDocuments,
  useGetUser,
  useUpdateAssistantProcessMutation,
} from "@hooks";
import { AssistantProcess, AssistantProcessStatus } from "@models";
import {
  EditOutlined,
  NoteAddOutlined,
  TrendingFlat,
} from "@mui/icons-material";
import { Alert, Typography } from "@mui/material";
import { DocumentStatus } from "@types";
import { fillRouteWithSlugs } from "@utils";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ListCard } from "src/components/Assistant/ListCard";
import { LoadingDocList } from "src/components/Assistant/LoadingDocList";
import { NavigationButtons } from "src/components/Assistant/NavigationButtons";
import { NoDocsToUpdate } from "src/components/Assistant/NoDocsToUpdate";
import { config as templateAssistantConfig } from "src/config/assistant/doc-config";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { TEMPLATE_TYPE } from "src/stores/models/template-document";
import {
  getDocTypeStatus,
  getIncompleteDependenciesRecursively,
} from "src/utils/documents";

export const DU_ListOfDocsToChange = () => {
  const { data: user } = useGetUser();
  const { orgId = "", deviceId = "" } = useParams();
  const { data: documents } = useGetDocuments(orgId, deviceId);
  const { data: device } = useGetDevice({ orgId, deviceId });
  const docEngine = useDocEngine(deviceId);
  const { data: assistantProcesses } = useGetAssistantProcesses({
    orgId,
    deviceId,
    status: AssistantProcessStatus.IN_PROGRESS,
  });
  const { mutate: updateAssistantProcess } = useUpdateAssistantProcessMutation(
    orgId,
    deviceId
  );

  const selectedAssistantProcess = assistantProcesses?.[0];

  const navigate = useNavigate();

  const [showLoading, setShowLoading] = useState(true);

  const handleNext = (assistantProcess: AssistantProcess) => {
    const updatedState: AssistantProcess["state"] = {
      ...assistantProcess.state,
      currentPath: ROUTES.ASSISTANT_DU_MULTI_STEP_FORM,
      changes: assistantProcess.state.changes.map((change) => ({
        ...change,
        dataKeyId: change.dataKeyId,
      })),
    };

    updateAssistantProcess({
      processId: assistantProcess.id,
      body: {
        state: updatedState,
      },
    });

    navigate(
      fillRouteWithSlugs(ROUTES.ASSISTANT_DU_MULTI_STEP_FORM, {
        [ROUTE_SLUGS.ORG_ID]: orgId,
        [ROUTE_SLUGS.DEVICE_ID]: deviceId,
      })
    );
  };

  // Show the loading screen for at least3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (
    showLoading ||
    !selectedAssistantProcess ||
    !documents ||
    !user ||
    !device
  ) {
    return <LoadingDocList />;
  }

  const dataKeysToUpdate = selectedAssistantProcess.state.dataKeysToUpdate;

  if (!dataKeysToUpdate || dataKeysToUpdate.length === 0) {
    return <NoDocsToUpdate />;
  }

  let templateIdsToComplete: TEMPLATE_TYPE[] = [];
  let incompleteDocsList: TEMPLATE_TYPE[] = [];

  // Sort out incomplete documents & documents to show for update
  dataKeysToUpdate.forEach((dataKeyToUpdate) => {
    const docStatus = getDocTypeStatus({
      documents,
      templateId: dataKeyToUpdate.templateId as TEMPLATE_TYPE,
      user,
      ignoreEntitlement: true,
      orgId,
      device,
    });

    if (docStatus !== DocumentStatus.LOCKED) {
      templateIdsToComplete.push(dataKeyToUpdate.templateId as TEMPLATE_TYPE);

      const templateConfig =
        templateAssistantConfig[dataKeyToUpdate.templateId as TEMPLATE_TYPE];

      if (
        docStatus == DocumentStatus.DRAFT &&
        templateConfig.docType !== "RCD"
      ) {
        incompleteDocsList.push(dataKeyToUpdate.templateId as TEMPLATE_TYPE);
      }

      const incompleteDocuments = getIncompleteDependenciesRecursively(
        dataKeyToUpdate.templateId as TEMPLATE_TYPE,
        documents,
        user,
        orgId,
        device
      );

      incompleteDocsList = [
        ...new Set([...incompleteDocsList, ...incompleteDocuments]),
      ];
    }
  });

  const hasIncompleteDocuments = incompleteDocsList.length > 0;

  return (
    <div className="flex flex-1 flex-col gap-y-6">
      <div className="flex flex-col gap-y-3">
        <Typography variant="h1" className="flex items-center gap-2">
          Assistant <Chip label="beta" /> - Document Update
        </Typography>
        <Typography>
          {hasIncompleteDocuments
            ? "You need to complete these documents before you can start:"
            : "Based on your input those are the documents that need to be updated:"}
        </Typography>
      </div>
      <div className="flex flex-col gap-y-4">
        {hasIncompleteDocuments ? (
          <>
            <Alert severity="warning">
              Please complete or delete the drafts for the following documents
              before you can start:
            </Alert>
            <div className="flex flex-col gap-y-2">
              {incompleteDocsList.map((incompleteId) => {
                return (
                  <ListCard
                    key={incompleteId}
                    text={ASSISTANT_CONFIG[incompleteId].docName}
                    color="rgb(237, 108, 2)"
                    iconColor="warning"
                    StartIcon={EditOutlined}
                    EndIcon={TrendingFlat}
                    fullWidth
                    onClick={async () =>
                      await docEngine.navigateToWizardOrQMS({
                        templateId: incompleteId,
                        device,
                        documents,
                        user,
                      })
                    }
                  />
                );
              })}
            </div>
          </>
        ) : (
          <>
            <Alert severity="warning">
              By going through the process we will help you adjust necessary
              information and create new versions of the following documents.
            </Alert>
            <div className="flex flex-col gap-y-2">
              {[...new Set(templateIdsToComplete)].map((templateId) => {
                const templateConfig =
                  ASSISTANT_CONFIG[templateId as TEMPLATE_TYPE];

                const docStatus = getDocTypeStatus({
                  documents,
                  templateId: templateId as TEMPLATE_TYPE,
                  user,
                  ignoreEntitlement: true,
                  orgId,
                  device,
                });

                return (
                  <ListCard
                    key={templateConfig.docName}
                    text={templateConfig.docName}
                    StartIcon={NoteAddOutlined}
                    fullWidth
                    justifyContent="flex-start"
                    cursor="default"
                    disabled={docStatus === DocumentStatus.LOCKED}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
      <NavigationButtons
        rightButton={{
          disabled: hasIncompleteDocuments,
          onClick: () => handleNext(selectedAssistantProcess),
        }}
        leftButton={{
          hasConfirmationModal: true,
        }}
      />
    </div>
  );
};
