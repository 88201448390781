export enum ROUTE_SLUGS {
  ORG_ID = ":orgId",
  DEVICE_ID = ":deviceId",
  ARTICLE_ID = ":articleId",
  TEMPLATE_ID = ":templateId",
  DOC_ID = ":docId",
  FILE_ID = ":fileId",
}

export enum ROUTES {
  CALLBACK = "/callback",
  DEVICE_MANAGEMENT = `/${ROUTE_SLUGS.ORG_ID}/device-management`,
  DEVICE_OVERVIEW = `/${ROUTE_SLUGS.ORG_ID}/device/${ROUTE_SLUGS.DEVICE_ID}`,
  ERROR = `/${ROUTE_SLUGS.ORG_ID}/error`,
  FORM = `/${ROUTE_SLUGS.ORG_ID}/form`,
  LOGIN = "/login",
  HELP_CENTER = `/help-center`,
  HELP_CENTER_ARTICLE = `/help-center/${ROUTE_SLUGS.ARTICLE_ID}`,
  QMS = `/${ROUTE_SLUGS.ORG_ID}/device/${ROUTE_SLUGS.DEVICE_ID}/documents`,
  QMS_OPEN_DOC = `/${ROUTE_SLUGS.ORG_ID}/device/${ROUTE_SLUGS.DEVICE_ID}/documents/${ROUTE_SLUGS.TEMPLATE_ID}`,
  QMS_OPEN_FILE = `/${ROUTE_SLUGS.ORG_ID}/device/${ROUTE_SLUGS.DEVICE_ID}/files/${ROUTE_SLUGS.FILE_ID}`,
  SIGNUP = "/signup",
  TEMPLATE_ASSISTANT = `/${ROUTE_SLUGS.ORG_ID}/device/${ROUTE_SLUGS.DEVICE_ID}/template-assistant/${ROUTE_SLUGS.TEMPLATE_ID}/${ROUTE_SLUGS.DOC_ID}`,
  TEMPLATE_EDITOR = `/${ROUTE_SLUGS.ORG_ID}/device/${ROUTE_SLUGS.DEVICE_ID}/templates/${ROUTE_SLUGS.TEMPLATE_ID}`,
  SUBSCRIPTION = `/${ROUTE_SLUGS.ORG_ID}/subscription`,
  CHECKOUT_SUCCESS = `/${ROUTE_SLUGS.ORG_ID}/checkout/success`,
  MANAGE_ORGANIZATION = `/${ROUTE_SLUGS.ORG_ID}/settings`,
  ASSISTANT = `/${ROUTE_SLUGS.ORG_ID}/device/${ROUTE_SLUGS.DEVICE_ID}/assistant`,
  ASSISTANT_DU_CONFIGURE = `/${ROUTE_SLUGS.ORG_ID}/device/${ROUTE_SLUGS.DEVICE_ID}/assistant/document-update/configure`,
  ASSISTANT_DU_LIST_OF_DOCS_TO_CHANGE = `/${ROUTE_SLUGS.ORG_ID}/device/${ROUTE_SLUGS.DEVICE_ID}/assistant/document-update/to-change`,
  ASSISTANT_DU_MULTI_STEP_FORM = `/${ROUTE_SLUGS.ORG_ID}/device/${ROUTE_SLUGS.DEVICE_ID}/assistant/document-update/editing`,
  ASSISTANT_DU_GENERATING_DOCS = `/${ROUTE_SLUGS.ORG_ID}/device/${ROUTE_SLUGS.DEVICE_ID}/assistant/generating-documents`,
  ASSISTANT_DU_UPDATE_DOCS = `/${ROUTE_SLUGS.ORG_ID}/device/${ROUTE_SLUGS.DEVICE_ID}/assistant/document-for-update`,
}
