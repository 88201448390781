import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StepValue } from "@types";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";

type Props = {
  value: string;
  disabled?: boolean;
  onChange: (value: StepValue) => void;
  style?: React.CSSProperties;
  label?: string;
  hideLabel?: boolean;
};

export default function DateInput({
  disabled,
  onChange,
  value,
  style,
  label,
  hideLabel,
}: Props) {
  const [currentValue, setCurrentValue] = React.useState<Dayjs | null>(
    value ? dayjs(value, "YYYY-MM-DD") : null
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateField
        disabled={disabled}
        style={style}
        label={label}
        hiddenLabel={hideLabel}
        value={currentValue}
        format="YYYY-MM-DD"
        onChange={(newValue) => {
          setCurrentValue(newValue);
          if (dayjs(newValue, "YYYY-MM-DD", true).isValid()) {
            onChange({
              answer: dayjs(newValue).format("YYYY-MM-DD"),
            });
          }
        }}
      />
    </LocalizationProvider>
  );
}
