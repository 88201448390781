export function RetroGrid({ angle = 65 }: { angle?: number }) {
  return (
    <div
      className={
        "pointer-events-none absolute left-0 size-full overflow-hidden opacity-50 [perspective:200px]"
      }
      style={{ "--grid-angle": `${angle}deg` } as React.CSSProperties}
    >
      {/* Grid */}
      <div className="absolute inset-0 [transform:rotateX(var(--grid-angle))]">
        <div
          className={
            "animate-grid [background-repeat:repeat] [background-size:60px_60px] [height:400vh] [inset:0%_0px] [margin-left:-50%] [transform-origin:100%_0_0] [width:600vw] [background-image:linear-gradient(to_right,rgba(0,0,0,0.3)_1px,transparent_0),linear-gradient(to_bottom,rgba(0,0,0,0.3)_1px,transparent_0)] dark:[background-image:linear-gradient(to_right,rgba(255,255,255,0.2)_1px,transparent_0),linear-gradient(to_bottom,rgba(255,255,255,0.2)_1px,transparent_0)]"
          }
        />
      </div>
      {/* Background Gradients */}
      <div className="absolute inset-0 bg-gradient-to-t from-white to-transparent to-90% dark:from-black" />
      {/* Added Top Gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-white to-transparent to-100% dark:from-black" />
    </div>
  );
}
