const ProgressBar = ({ progress }: { progress: number }) => {
  return (
    <div className="w-80 h-fit pb-0 box-border">
      <div className="h-[10px] bg-[#808080] rounded-full">
        <div
          className="h-[10px] bg-[#0089F9] rounded-full transition-all duration-1000 ease-in-out"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
