import { useEffect, useState } from "react";

type LocalHistory = {
  past: string[];
  present: string | null;
  future: string[];
};

type Props = {
  // docVersionId: string;
  elementId: string;
};

type ReturnValue = [
  (newValue?: string) => void,
  () => string | null,
  () => string | null,
];

export function useAnswerHistory({ elementId }: Props): ReturnValue {
  const [_, setInternalState] = useState<LocalHistory>(() => {
    return {
      past: [],
      present: null,
      future: [],
    };
  });

  useEffect(() => {
    // reset state when elementId changes
    setInternalState({
      past: [],
      present: null,
      future: [],
    });
  }, [elementId]);

  function updateHistory(value?: string): void {
    setInternalState((prevState) => {
      if (typeof value === "string" && prevState.present !== value) {
        return constructState(
          [...prevState.past, prevState.present],
          value,
          []
        );
      } else {
        return prevState;
      }
    });
  }

  /**
   * Pass in the past, present, and future states to construct a new state object with the following rules:
   * - past and future arrays are limited to 10 items
   * - null values are removed from past and future arrays
   * @param past
   * @param present
   * @param future
   * @returns LocalHistory
   */
  function constructState(
    past: Array<string | null>,
    present: string,
    future: Array<string | null>
  ): LocalHistory {
    return {
      past: past.filter((p) => p !== null).slice(-10),
      present: present,
      future: future.filter((p) => p !== null).slice(0, 10),
    };
  }

  function undo(): string | null {
    let newPresent = null;
    setInternalState((prevState) => {
      if (prevState.past.length === 0) return prevState;
      const previous = prevState.past[prevState.past.length - 1];
      newPresent = previous;
      return constructState(prevState.past.slice(0, -1), previous, [
        prevState.present,
        ...prevState.future,
      ]);
    });
    return newPresent;
  }

  function redo(): string | null {
    let newPresent = null;
    setInternalState((prevState) => {
      if (prevState.future.length === 0) return prevState;
      const next = prevState.future[0];
      newPresent = next;
      return constructState(
        [...prevState.past, prevState.present],
        next,
        prevState.future.slice(1)
      );
    });
    return newPresent;
  }

  return [updateHistory, undo, redo];
}
