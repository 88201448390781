import { Chip } from "@components";
import { TrendingFlat } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ListCard } from "src/components/Assistant/LoadingDocuments";
import { ROUTE_SLUGS, ROUTES } from "src/config/navigation/routes";
import { useCrisp } from "src/hooks/Crisp";
import { usePostAssistantProcessMutation } from "src/hooks/mutations";
import { fillRouteWithSlugs } from "src/utils/navigation";

export const Assistant = () => {
  const { deviceId = "", orgId = "" } = useParams();
  const { supportOpen, setSupportOpen } = useCrisp();
  const navigate = useNavigate();

  const postAssistantProcess = usePostAssistantProcessMutation();

  return (
    <div className="flex flex-1 flex-col gap-y-6">
      <div className="flex flex-col gap-y-3">
        <Typography variant="h1" className="flex items-center gap-2">
          Assistant <Chip label="beta" />
        </Typography>
        <Typography>
          Get help completing some common tasks with our assistant.
        </Typography>
      </div>
      <div className="flex flex-col gap-y-3">
        <ListCard
          text="I need help knowing what documents to change or update."
          EndIcon={TrendingFlat}
          onClick={async () => {
            await postAssistantProcess.mutateAsync({
              data: {
                state: {
                  changeType: "update",
                  currentPath: ROUTES.ASSISTANT_DU_CONFIGURE,
                  checkboxes: [],
                  description: "",
                  changes: [],
                  dataKeysToUpdate: [],
                },
              },
              orgId,
              deviceId,
            });
            navigate(
              fillRouteWithSlugs(ROUTES.ASSISTANT_DU_CONFIGURE, {
                [ROUTE_SLUGS.ORG_ID]: orgId,
                [ROUTE_SLUGS.DEVICE_ID]: deviceId,
              })
            );
          }}
        />
        <ListCard
          text="I'd like to get some help from a human"
          EndIcon={TrendingFlat}
          onClick={() => setSupportOpen(!supportOpen)}
        />
      </div>
    </div>
  );
};
