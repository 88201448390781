import {
  AssistantProcessStatus,
  BaseUser,
  BaseUserSchema,
  Device,
  Document,
  DocumentAnswerItemSchema,
  DocumentSchema,
  DocumentVersionApproverSchema,
  TEMPLATE_TYPE,
  UserSchema,
  UserSurveyAnswersSchema,
  UserTodosSchema,
  deviceSchema,
  validateAssistantProcesses,
} from "@models";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryClient as queryClientAsync } from "@utils";
import { PostHog, usePostHog } from "posthog-js/react";
import { setTracingContext } from "src/utils/user";
import {
  ASSISTANT_CONFIG,
  getAllFilesInOrgQueryKey,
  getAnswerItemsByTypeQueryKey,
  getAnswerItemsQueryKey,
  getApproversQueryKey,
  getAssistantProcessesQueryKey,
  getDeviceQueryKey,
  getDevicesQueryKey,
  getDocumentQueryKey,
  getDocumentsQueryKey,
  getFileQueryKey,
  getOrganizationUsersQueryKey,
  getTasksQueryKey,
  getUserByIdQueryKey,
  getUserQueryKey,
  getUserSurveyAnswersQueryKey,
  getUserTodosQueryKey,
} from "../config";
import {
  getAllFilesInOrg,
  getAnswerItemsById,
  getAnswerItemsByTypeId,
  getAssistantProcesses,
  getDevice,
  getDevices,
  getDocument,
  getDocumentApprovers,
  getDocuments,
  getFile,
  getUser,
  getUserById,
  getUserSurveyAnswers,
  getUserTodos,
  getUsersByOrg,
} from "../services";
import { rootStore } from "../stores";

const { deviceStore } = rootStore;

const getUserParams = (enabled: boolean = true, postHog?: PostHog) => {
  return {
    queryKey: getUserQueryKey(),
    queryFn: async () => {
      const { data } = await getUser();
      const user = await UserSchema.validate(data);
      // Important for logging and debugging
      setTracingContext(user, postHog);
      return user;
    },
    enabled,
  };
};

export function useGetUser(enabled: boolean = true) {
  //TODO: Fix posthog to work for both hooks/function
  // Also fix: Warning: [PostHog.js] was already loaded elsewhere even thoug it seems to be a common issue
  // https://github.com/PostHog/posthog-js/issues/723
  const postHog = usePostHog();

  return useQuery(getUserParams(enabled, postHog));
}

export function getUserAsync(enabled: boolean = true) {
  return queryClientAsync.fetchQuery(getUserParams(enabled));
}

const getUserByIdParams = (userId: string) => {
  return {
    queryKey: getUserByIdQueryKey(userId),
    queryFn: async () => {
      const { data } = await getUserById(userId);
      return await BaseUserSchema.validate(data);
    },
  };
};

export function useGetUserById({ userId }: { userId: string }) {
  return useQuery(getUserByIdParams(userId));
}

export function useGetUserByIdAsync({ userId }: { userId: string }) {
  return queryClientAsync.fetchQuery(getUserByIdParams(userId));
}

export function useGetUserTodos(
  orgId: string,
  deviceId: string,
  enabled: boolean = false
) {
  return useQuery({
    queryKey: getUserTodosQueryKey(deviceId),
    queryFn: async () => {
      const { data } = await getUserTodos(orgId, deviceId);
      return await UserTodosSchema.validate(data);
    },
    enabled,
  });
}

export function useGetUserSurveyAnswers({ userId }: { userId: string }) {
  return useQuery({
    queryKey: getUserSurveyAnswersQueryKey(userId),
    queryFn: async () => {
      const { data } = await getUserSurveyAnswers();

      if (!data) {
        return null;
      }

      // this is because the user might not have any data yet and then data is an empty string
      return await UserSurveyAnswersSchema.validate(data);
    },
  });
}

export function useGetDocumentApproversQuery({
  documentId,
  versionId,
  orgId,
  deviceId,
}: {
  documentId: string;
  versionId: string;
  orgId: string;
  deviceId: string;
}) {
  return useQuery({
    queryKey: getApproversQueryKey(documentId, versionId),
    queryFn: async () => {
      const { data } = await getDocumentApprovers({
        orgId,
        documentId,
        versionId,
        deviceId,
      });
      return await Promise.all(
        data.map(
          async (approver: any) =>
            await DocumentVersionApproverSchema.validateSync(approver)
        )
      );
    },
  });
}

export function useGetUsersByOrg({
  orgId,
  includeAutoApprovers,
}: {
  orgId: string;
  includeAutoApprovers?: boolean;
}) {
  return useQuery({
    queryKey: getOrganizationUsersQueryKey({
      orgId,
    }),
    queryFn: async () => {
      let users: BaseUser[] = [];
      let skip = 0;
      while (skip !== null) {
        const { data } = await getUsersByOrg({
          orgId,
          skip,
          includeAutoApprovers,
        });
        users.push(...data.users);
        skip = data.nextSkip;
      }
      return await Promise.all(
        users.map(async (user: any) => await BaseUserSchema.validate(user))
      );
    },
  });
}

export function useGetDevices({ orgId }: { orgId: string }) {
  return useQuery({
    queryKey: getDevicesQueryKey(orgId),
    queryFn: async () => {
      const { data } = await getDevices(orgId);
      return Promise.all(
        data.map(async (device: Device) => {
          const validated = await deviceSchema.validate(device);
          return validated;
        })
      );
    },
  });
}

export function useGetDevice({
  orgId,
  deviceId,
}: {
  orgId: string;
  deviceId: string;
}) {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: getDeviceQueryKey(deviceId),
    queryFn: async () => {
      const { data } = await getDevice(orgId, deviceId);
      return await deviceSchema.validate(data);
    },
    initialData: () => {
      const devices: Device[] | undefined = queryClient.getQueryData([
        "devices",
      ]);
      return devices?.find((device: Device) => device.id === deviceId);
    },
  });
}

export function useGetAnswerItemsByType({
  orgId,
  type,
}: {
  orgId: string;
  type: string;
}) {
  return useQuery({
    queryKey: getAnswerItemsByTypeQueryKey({ orgId, type }),
    queryFn: async () => {
      const { data } = await getAnswerItemsByTypeId({
        orgId,
        type,
      });

      return await Promise.all(
        data.map(
          async (item: any) => await DocumentAnswerItemSchema.validate(item)
        )
      );
    },
    enabled: !!type,
  });
}

export function useGetAnswerItemById({
  orgId,
  itemId,
  type,
}: {
  orgId: string;
  itemId: string;
  type: string;
}) {
  return useQuery({
    queryKey: getAnswerItemsQueryKey({ orgId, itemId, type }),
    queryFn: async () => {
      const { data } = await getAnswerItemsById({
        orgId,
        itemId,
      });

      return await Promise.all(
        data.map(
          async (item: any) => await DocumentAnswerItemSchema.validate(item)
        )
      );
    },
    enabled: !!itemId,
  });
}

const getDocumentsParams = (orgId: string, deviceId: string) => {
  return {
    queryKey: getDocumentsQueryKey(deviceId),
    queryFn: async () => {
      const { data } = await getDocuments(orgId, deviceId);
      const docs: Document[] = await Promise.all(
        data.map(async (d: any) => DocumentSchema.validateSync(d))
      );
      return docs.filter((doc) => ASSISTANT_CONFIG[doc.name as TEMPLATE_TYPE]);
    },
    staleTime: 1000 * 60,
  };
};

export function useGetDocuments(orgId: string, deviceId: string) {
  return useQuery(getDocumentsParams(orgId, deviceId));
}

export function getDocumentsAsync(orgId: string, deviceId: string) {
  return queryClientAsync.fetchQuery(getDocumentsParams(orgId, deviceId));
}

const getTasksQueryParams = (orgId: string, deviceId: string) => {
  return {
    queryKey: getTasksQueryKey(deviceId),
    queryFn: () => deviceStore.fetchRoadmapTasks(orgId, deviceId),
    staleTime: 1000 * 60,
  };
};

// To allow passing data do options?: QueryOptions<RoadmapTasks[]> Passing the type of the data is important otherwise react-query will not know what type of data to expect
export function useGetTasks(orgId: string, deviceId: string) {
  return useQuery(getTasksQueryParams(orgId, deviceId));
}

export function getTasksAsync(orgId: string, deviceId: string) {
  return queryClientAsync.fetchQuery(getTasksQueryParams(orgId, deviceId));
}

export const useGetDocument = (
  orgId: string,
  deviceId: string,
  docId: string = ""
) => {
  return useQuery({
    queryKey: getDocumentQueryKey(deviceId, docId),
    queryFn: async () => {
      const { data, status } = await getDocument(orgId, deviceId, docId);
      if (status !== 200) {
        return null;
      }
      return DocumentSchema.validate(data);
    },
    enabled: !!docId,
  });
};

export const useGetFile = ({
  orgId,
  fileId,
}: {
  orgId: string;
  fileId: string;
}) => {
  return useQuery({
    queryKey: getFileQueryKey(fileId),
    queryFn: async () => {
      const data = await getFile({ orgId, fileId });
      // return FileSchema.validate(data);
      return data;
    },
    enabled: !!fileId,
  });
};

export const useGetAllFilesInOrg = ({
  orgId,
  deviceId,
}: {
  orgId: string;
  deviceId?: string;
}) => {
  return useQuery({
    queryKey: getAllFilesInOrgQueryKey(deviceId),
    queryFn: async () => {
      const { data } = await getAllFilesInOrg({ orgId, deviceId });
      return data;
    },
  });
};

export const useGetAssistantProcesses = ({
  orgId,
  deviceId,
  status,
  enabled,
}: {
  orgId: string;
  deviceId: string;
  status?: AssistantProcessStatus;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: getAssistantProcessesQueryKey(deviceId),
    queryFn: async () => {
      const { data } = await getAssistantProcesses(orgId, deviceId, status);

      const processes = await validateAssistantProcesses(data);
      return processes;
    },
    enabled,
  });
};
