import { TextField as TextFieldMui } from "@mui/material";
import { StepValue, TextFieldElement as TextFieldElementProps } from "@types";
import React from "react";

type Props = {
  element: TextFieldElementProps;
  value: string;
  disabled?: boolean;
  onChange: (id: string, value: StepValue) => void;
  textFieldReadOnly?: boolean;
};

export const TextField: React.FC<Props> = ({
  disabled,
  onChange,
  element,
  value,
  textFieldReadOnly = false,
}) => {
  return (
    <div key={element.id} className="flex flex-col">
      <TextFieldMui
        inputProps={{ "data-testid": element.id }}
        hiddenLabel
        type={element.options.type}
        key={element.id}
        id={element.id}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange(element.id, { answer: event.target.value })
        }
        multiline={!!element.options.rows}
        fullWidth
        value={value}
        minRows={element.options.rows}
        disabled={disabled}
        InputProps={{
          readOnly: textFieldReadOnly,
        }}
      />
    </div>
  );
};
export default TextField;
