import { UserSurveyAnswers } from "@models";

import { User } from "@models";
import { StepConfig, steps } from "src/components/Onboarding/step-config";

export const getInitialStep = ({
  user,
  survey,
}: {
  user: User;
  survey: UserSurveyAnswers | null;
}) => {
  const hasUserName = user.firstName && user.lastName;
  const isEmptyOrUndefined = (value?: string | null) => !value || value === "";

  if (!survey) {
    return 0;
  }

  const hasAnyData =
    !isEmptyOrUndefined(survey.productTour) ||
    !isEmptyOrUndefined(survey.productStage) ||
    !isEmptyOrUndefined(survey.productCertification) ||
    !isEmptyOrUndefined(survey.productInterest);

  if (!hasAnyData) return 0;

  if (!hasUserName) return 1;

  const hasProductInfo =
    isEmptyOrUndefined(survey.productStage) ||
    isEmptyOrUndefined(survey.productCertification);
  if (hasProductInfo) {
    return 2;
  }

  const hasProductInterest =
    isEmptyOrUndefined(survey.productInterest) ||
    isEmptyOrUndefined(survey.productTour);
  if (hasProductInterest) {
    return 3;
  }

  return 5;
};

export const calculateSurveyProgress = ({
  currentStep: currentWelcomeStep,
  shouldIgnoreUserNameStep,
}: {
  currentStep: StepConfig;
  shouldIgnoreUserNameStep: boolean;
}) => {
  const surveyStep =
    steps
      .filter((s) => s.id.includes("survey"))
      .findIndex((s) => s.id === currentWelcomeStep.id) + 1;

  const totalSurveySteps = steps.filter((s) => s.id.includes("survey")).length;

  const currentProgress =
    ((surveyStep - (shouldIgnoreUserNameStep ? 1 : 0)) /
      (totalSurveySteps - (shouldIgnoreUserNameStep ? 1 : 0))) *
    100;

  return currentProgress;
};

export const checkIsDisabledStep = ({
  currentStep,
  user,
  survey,
  isSurveyStep,
}: {
  currentStep: StepConfig;
  user: { firstName: string; lastName: string };
  survey: UserSurveyAnswers | undefined | null;
  isSurveyStep: boolean;
}) => {
  const ignoreValidation = currentStep.id.includes("start") || !isSurveyStep;

  if (ignoreValidation) return false;

  return currentStep.inputFields?.some((field) => {
    if (currentStep.id === "survey-name") {
      return !user[field.fieldId as keyof typeof user];
    }

    return !survey || !survey[field.fieldId as keyof UserSurveyAnswers];
  });
};
