import {
  useAnswerHistory,
  useDebounce,
  useUpdateAssistantProcessMutation,
} from "@hooks";
import { ChevronRight } from "@mui/icons-material";
import { Button } from "@mui/material";
import { isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ElementMapper } from "src/components/Form/ElementMapper";
import { StepHeader } from "src/components/Form/StepHeader";
import {
  getAnswer,
  getAnswerFromDocumentVersion,
  runTransformer,
} from "src/components/Form/utils";
import { useSuggestion } from "src/components/FormStep/utils/useSuggestion";
import { isStepComplete } from "src/utils/step";
import { hasGenericSuggestion, hasInlineSuggestion } from "src/utils/wizard";
import {
  AssistantMultiStepFormAnswer,
  AssistantProcess,
  Device,
  Document,
  DocumentVersion,
  TEMPLATE_TYPE,
} from "../../stores/models";
import { StepValue, TemplateElement } from "../../types";
import { Suggestion } from "../Form/Suggestion";

type Props = {
  templateId: TEMPLATE_TYPE;
  step: TemplateElement;
  documentVersion?: DocumentVersion;
  nextStep: () => void;
  documents: Document[];
  device: Device;
  assistantProcess: AssistantProcess;
};

export const AssistantMultiStepFormStep = ({
  templateId,
  documentVersion,
  step,
  nextStep,
  documents,
  device,
  assistantProcess,
}: Props) => {
  const { orgId = "", deviceId = "" } = useParams();
  const { mutate: updateAssistantProcess } = useUpdateAssistantProcessMutation(
    orgId,
    deviceId
  );
  const isAnswerStringElement =
    step.element.type !== "answerItemsElement" &&
    step.element.type !== "fileUpload";

  const defaultAnswer = {
    answer: "",
    answerFileId: undefined,
    answerItems: undefined,
  };

  const savedChange = assistantProcess.state.changes.find(
    (change) => change.dataKeyId === step.id
  );

  const savedPreviousAnswer: StepValue | undefined = useMemo(() => {
    if (documentVersion) {
      const answerFromExistingDocument = getAnswerFromDocumentVersion(
        step.element,
        documentVersion
      );
      if (answerFromExistingDocument) {
        return {
          answer: answerFromExistingDocument.answer,
          answerFileId: answerFromExistingDocument.answerFileId,
          answerItems: answerFromExistingDocument.answerItems,
        };
      }
    }

    return undefined;
  }, [savedChange?.previousAnswer, step, documentVersion, documents]);

  const defaultAcceptedAnswer = useMemo(() => {
    return getAnswer({
      step,
      documentVersion,
      documents,
    });
  }, [step, documentVersion, documents]);

  const defaultAnswerIsNotEmpty = useMemo(() => {
    return !isEqual(defaultAcceptedAnswer, {
      answer: undefined,
      answerFileId: undefined,
      answerItems: undefined,
    });
  }, [defaultAcceptedAnswer]);

  // For the new answer we can also run the transformer and all other logic we use for new answers
  const newAnswer =
    savedChange?.acceptedAnswer ||
    savedPreviousAnswer ||
    (defaultAnswerIsNotEmpty
      ? getAnswer({
          step,
          documentVersion,
          documents,
        })
      : undefined);

  const eligibleForGenericSuggestion = hasGenericSuggestion(
    step,
    assistantProcess
  );
  const eligibleForInlineSuggestion =
    step.element.type === "table" && eligibleForGenericSuggestion;

  const hasPrompt = step.prompt || eligibleForGenericSuggestion;

  // make sure that for inline suggestions that have a previous answer we set prompt enabled to false

  const [assistantStepState, setAssistantStepState] =
    useState<AssistantMultiStepFormAnswer>({
      dataKeyId: step.id,
      templateId: templateId,
      previousAnswer: savedPreviousAnswer,
      acceptedAnswer: newAnswer,
      suggestion: savedChange?.suggestion || undefined,
    });

  const stepHasInlineSuggestion =
    !assistantStepState.previousAnswer &&
    (hasInlineSuggestion(step) || eligibleForInlineSuggestion);
  //const stepHasInlineSuggestion = hasInlineSuggestion(step);

  const stepIsComplete: boolean = useMemo(() => {
    return (
      !!assistantStepState.acceptedAnswer &&
      isStepComplete(step, assistantStepState.acceptedAnswer)
    );
  }, [assistantStepState.acceptedAnswer, step]);

  // const suggestionIsDisabled = step.element.type === "select";

  const assistantProcessWithLocalStepState = {
    ...assistantProcess,
    state: {
      ...assistantProcess.state,
      changes: [
        ...assistantProcess.state.changes.filter(
          (change) => change.dataKeyId !== step.id
        ),
        assistantStepState,
      ],
    },
  };

  const {
    suggestion: newSuggestion,
    refreshSuggestion,
    workflowState,
  } = useSuggestion({
    step,
    documentVersion,
    device,
    documents,
    savedSuggestion: assistantStepState.suggestion,
    assistantProcess: assistantProcessWithLocalStepState,
    enabled: hasPrompt,
  });

  const saveAssistantProcessStep = (step: AssistantMultiStepFormAnswer) => {
    updateAssistantProcess({
      processId: assistantProcess.id,
      body: {
        state: {
          ...assistantProcess.state,
          changes: [
            ...assistantProcess.state.changes.filter(
              (change) => change.dataKeyId !== step.dataKeyId
            ),
            step,
          ],
        },
      },
    });
  };

  const { debounce, cancelDebounce } = useDebounce(
    2000,
    (step: AssistantMultiStepFormAnswer) => {
      if (isAnswerStringElement) {
        saveAssistantProcessStep(step);
      }
    }
  );

  const handleNextStep = () => {
    cancelDebounce();
    saveAssistantProcessStep(assistantStepState);
    nextStep();
  };

  const handleChange = (value: StepValue) => {
    if (isEqual(value, defaultAnswer)) {
      return;
    }
    setAssistantStepState((prev) => {
      const newState = {
        ...prev,
        acceptedAnswer: value,
      };
      debounce(newState);
      return newState;
    });
  };

  // console.log("assistantStepState", assistantStepState);

  const [updateHistory, undo, redo] = useAnswerHistory({
    elementId: step.id,
  });

  /**
   * In order to keep the history of the answer, we need to update the history when the answer changes
   */
  useEffect(() => {
    if (isAnswerStringElement) {
      updateHistory(assistantStepState.acceptedAnswer?.answer);
    }
  }, [assistantStepState.acceptedAnswer]);

  const handleUndo = () => {
    const newAnswer = undo();
    if (newAnswer) {
      handleChange({ answer: newAnswer });
    }
  };

  const handleRedo = () => {
    const newAnswer = redo();
    if (newAnswer) {
      handleChange({ answer: newAnswer });
    }
  };

  // Set suggestion on completion
  useEffect(() => {
    // only update the suggestion if it has changed
    // TODO this is messy and only happens cause newSuggestion is triggering right away
    if (isEqual(newSuggestion, savedChange?.suggestion)) {
      return;
    }
    setAssistantStepState((prev) => {
      const newState = {
        ...prev,
        suggestion: newSuggestion,
      };
      if (stepHasInlineSuggestion) {
        newState.acceptedAnswer = {
          answer: newSuggestion.value || "",
          answerFileId: undefined,
          answerItems: undefined,
        };
      }

      if (newSuggestion.completed) {
        saveAssistantProcessStep(newState);
      }

      return newState;
    });
  }, [newSuggestion]);

  const handleForceRunTransformer = () => {
    if (!documentVersion) {
      return;
    }
    const newAnswer = runTransformer({
      step,
      documentVersion,
      documents,
    });
    handleChange({ answer: newAnswer, answerFileId: undefined });
  };

  const hasLeftPanel =
    (hasPrompt &&
      assistantStepState.previousAnswer &&
      stepHasInlineSuggestion) ||
    (hasPrompt && !stepHasInlineSuggestion);

  // console.log(
  //   "hasLeftPanel",
  //   hasLeftPanel,
  //   hasPrompt,
  //   stepHasInlineSuggestion,
  //   assistantStepState.previousAnswer
  // );

  const leftPanelWidth = step.element.type === "table" ? "w-1/3" : "w-1/2";
  const mainPanelWidth = step.element.type === "table" ? "w-2/3" : "w-1/2";

  // Single panel elements with no suggestion
  return (
    <div key={step.id} className={`flex flex-1 flex-col gap-y-2`}>
      <StepHeader templateElement={step} documents={documents} />
      <div className="flex w-full flex-1 flex-row gap-4 relative">
        {hasLeftPanel && (
          <div
            className={`flex flex-col ${leftPanelWidth}`} /* style={{ width: `${width}px` }}*/
          >
            <Suggestion
              suggestion={newSuggestion}
              step={step}
              handleApply={(_, suggestion) =>
                handleChange({ answer: suggestion })
              }
              onRegenerateSuggestion={refreshSuggestion}
              workflowState={workflowState}
              fullWidth={true}
            />
          </div>
        )}
        <div className={`flex flex-1 flex-col gap-y-4 ${mainPanelWidth}`}>
          <ElementMapper
            step={step}
            hasInlineSuggestion={stepHasInlineSuggestion}
            stepValue={
              assistantStepState.acceptedAnswer ||
              assistantStepState.previousAnswer ||
              defaultAnswer
            }
            suggestion={
              stepHasInlineSuggestion
                ? assistantStepState.suggestion
                : undefined
            }
            documentVersion={documentVersion}
            templateId={templateId}
            title="Answer"
            onChange={(_, value) => handleChange(value)}
            onUndo={handleUndo}
            onRedo={handleRedo}
            onReRunTransformer={handleForceRunTransformer}
            onRegenerateSuggestion={refreshSuggestion}
          />
          <div className="self-end">
            <Button
              disabled={!stepIsComplete}
              onClick={handleNextStep}
              variant="contained"
              endIcon={<ChevronRight />}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
