import { ComponentConfigurationType, ComponentType } from "@models";
import { CreateDemoDeviceRequest } from "@types";
import { prefilledDocumentsConfig } from "./prefilled-documents";

export const SOFTWARE_DEMO_DEVICE: CreateDemoDeviceRequest = {
  deviceConfig: {
    name: "Software Demo - NeuroCare",
    description:
      "NeuroCare is a digital health application (mobile app) that uses health information and questionnaires to log mental health data for users, determine their risk of cognitive diseases like mild cognitive impairment or moderate alzheimer's disease, and provide recommendations on ways to live a better lifestyle as a way to possibly mitigate the changes of eventually having mental conditions. The app is available on a smart phone and is not intended to replace a doctor. Health data is manually added to the app or it is connected to the Apple Health kit to receive health data automatically.",
    isMDR: true,
    isFDA: false,
    isIVDR: false,
    components: [
      {
        name: "NeuroCare App",
        type: ComponentType.SOFTWARE,
        description:
          "NeuroCare is a digital health application (mobile app) that uses health information and questionnaires to log mental health data for users, determine their risk of cognitive diseases like mild cognitive impairment or moderate alzheimer's disease, and provide recommendations on ways to live a better lifestyle as a way to possibly mitigate the changes of eventually having mental conditions. The app is available on a smart phone and is not intended to replace a doctor. Health data is manually added to the app or it is connected to the Apple Health kit to receive health data automatically.",
        configuration: [
          {
            type: ComponentConfigurationType.HAS_AI,
            value: "true",
          },
        ],
        subComponents: [],
      },
    ],
  },
  documentsConfig: prefilledDocumentsConfig,
};
