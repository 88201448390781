import { Typography } from "@mui/material";
import Lottie from "react-lottie";
import animationData from "src/assets/gears.json";
import styles from "./Step.module.css";

const Loading = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
        }}
        height={300}
        width={300}
      />
      <Typography variant="h1" className={styles.loading}>
        Setting up your organization
      </Typography>
    </div>
  );
};

export default Loading;
