import {
  ComponentConfigurationType,
  ComponentType,
  SubComponentType,
} from "@models";
import { CreateDemoDeviceRequest } from "@types";
import { prefilledDocumentsConfig } from "./prefilled-documents";

export const SYSTEM_DEMO_DEVICE: CreateDemoDeviceRequest = {
  deviceConfig: {
    name: "Hardware and Software Demo - StressWear",
    description: `StressWear objectively measures stress using easy to wear biometric device around your ankle. It records your vital health information using sensors built into the ankle strap and uses that data to understand how stress impacts your wellbeing, productivity, and risk of illness. StressWear works proactively to provide personalized stress management program and risk scores that help you identify where your stress is causing you significant risk and how to manage that stress. The mobile app is intended to be used as a standalone software product and pulls data from the ankle strap for which it uses as input. 

The mobile application provides a allows the users to input, view, and manage their health data using a health dashboard and feature screens. The app provides the user access to the personalized stress management platform and displays risk scores for the user according to their health data. The app connects directly to the ankle strap to receive health information input which it is able to send to the backend database for storage and retrieval. The app uses an algorithm to analyze health data and provide risk scores as well as lifestyle recommendations based on stress.`,
    isMDR: true,
    isFDA: false,
    isIVDR: false,
    components: [
      {
        name: "Ankle strap",
        type: ComponentType.HARDWARE,
        description:
          "The ankle strap housing is composed of flexible plastic with adjustable straps to allow the device to be affixed to multiple ankle sizes. The ankle strap comes in contact with the skin and houses sensors that record the vital signs through the skin of the user. The ankle strap has an on and off button as well as an indicator light for power on and bluetooth connection.",
        configuration: [
          {
            type: ComponentConfigurationType.HAS_HUMAN_CONTACT,
            value: "true",
          },
        ],
        subComponents: [
          {
            type: SubComponentType.MECHANICAL,
            description:
              "The ankle strap has waterproof housing that contains the firmware and electrical parts as well as tactile button on the outer edge to allow power on and bluetooth on/off toggling. The ankle strap has a built in adjustable strap with a clasp.",
          },
          {
            type: SubComponentType.ELECTRICAL,
            description:
              "The ankle strap has a rechargeable battery with a usb-c plug for charging as well as sensors and receivers for measuring blood pressure, temperature, and other vital signs. The ankle strap has a bluetooth transmitter. There is an indicator light along the strap edge that can be blue or white depending on the function being enabled.",
          },
          {
            type: SubComponentType.FIRMWARE,
            description:
              "The ankle strap has firmware to capture sensor data and transmit signal via bluetooth.",
          },
        ],
      },
      {
        name: "Mobile app",
        type: ComponentType.SOFTWARE,
        description: `"The mobile app is intended to be used as a standalone software product and pulls data from the ankle strap for which it uses as input. 

The mobile application provides a allows the users to input, view, and manage their health data using a health dashboard and feature screens. The app provides the user access to the personalized stress management platform and displays risk scores for the user according to their health data. The app connects directly to the ankle strap to receive health information input which it is able to send to the backend database for storage and retrieval. The app uses an algorithm to analyze health data and provide risk scores as well as lifestyle recommendations based on stress."`,
        configuration: [
          {
            type: ComponentConfigurationType.HAS_AI,
            value: "true",
          },
        ],
        subComponents: [],
      },
    ],
  },
  documentsConfig: prefilledDocumentsConfig,
};
