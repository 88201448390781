import { DialogActions, DialogContent, Skeleton } from "@mui/material";

const WelcomeSkeleton = () => {
  return (
    <div className={`flex flex-col justify-center gap-8 h-full`}>
      <Skeleton variant="rectangular" width={280} height={40} />

      <div className="h-full gap-8">
        <DialogContent className="flex flex-col justify-center gap-4 p-0 h-full">
          <div className="flex flex-col justify-center gap-2.5 p-0">
            <Skeleton variant="rectangular" width={300} height={40} />

            <div className="flex flex-wrap gap-2.5">
              <Skeleton variant="rectangular" width={350} height={30} />
            </div>
          </div>
        </DialogContent>
      </div>

      <DialogActions className="p-0 self-start">
        <Skeleton variant="rectangular" width={120} height={40} />
      </DialogActions>
    </div>
  );
};

export default WelcomeSkeleton;
