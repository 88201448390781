import { useAuth0 } from "@auth0/auth0-react";
import { ROUTES } from "@config";
import {
  useGetAssistantProcesses,
  useGetUser,
  useUpdateUserUiStateMutation,
} from "@hooks";
import { AssistantProcessStatus, Plan } from "@models";
import { Dialog } from "@mui/material";
import * as Sentry from "@sentry/react";
import { fillRouteWithSlugs, getSubscription, WELCOME_MODAL_KEY } from "@utils";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Banner } from "src/components/Layout/Banner";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import useUiStateItemLock from "src/hooks/useUiStateItemLock";
import { getOrgFromUser } from "src/utils/user";
import { Fallback } from "..";
import Welcome from "../Onboarding/Welcome";
import { LeftDrawer } from "./LeftDrawer";

type Props = {
  children?: React.ReactNode;
};

export function MainLayout(props: Props) {
  const { orgId = "", deviceId = "" } = useParams();
  const { isAuthenticated } = useAuth0();
  const { data: user } = useGetUser(isAuthenticated);
  const org = getOrgFromUser(user, orgId);
  const subscription = org ? getSubscription(org) : null;
  const { closeItem: closeWelcomeModal, itemIsOpen: welcomeModalIsOpen } =
    useUiStateItemLock(WELCOME_MODAL_KEY);
  const patchUserUiStateMutation = useUpdateUserUiStateMutation();

  const currentPath = useLocation().pathname;

  const { data: assistantProcesses } = useGetAssistantProcesses({
    orgId,
    deviceId,
    status: AssistantProcessStatus.IN_PROGRESS,
    enabled: !!deviceId && !!orgId && !!user,
  });

  // when user has no name, even if they have completed the onboarding, we show the welcome modal so they can add their name
  if (user && (!user.firstName || !user.lastName) && !welcomeModalIsOpen) {
    patchUserUiStateMutation.mutate({
      welcomeSeen: false,
    });
  }

  const onboardingIsOpen =
    welcomeModalIsOpen || !user?.firstName || !user?.lastName;

  const isAssistantProcessInProgress =
    assistantProcesses?.[0]?.status === AssistantProcessStatus.IN_PROGRESS;
  const shouldShowBannerAssistant =
    isAssistantProcessInProgress &&
    !currentPath.includes("assistant") &&
    !!deviceId;

  const hasBanner = subscription?.products.includes(Plan.FREE);
  const isBannerVisible = !!hasBanner && !!subscription;
  const currentAssistantProcessUrl =
    assistantProcesses?.[0]?.state?.currentPath;

  return (
    <div className="flex h-full">
      {shouldShowBannerAssistant && (
        <Banner
          bannerText={"You have an ongoing assistant process."}
          buttonText={"Continue assistant"}
          buttonLink={fillRouteWithSlugs(
            currentAssistantProcessUrl && isAssistantProcessInProgress
              ? (currentAssistantProcessUrl as ROUTES)
              : ROUTES.ASSISTANT,
            {
              [ROUTE_SLUGS.ORG_ID]: orgId,
              [ROUTE_SLUGS.DEVICE_ID]: deviceId,
            }
          )}
          color="info"
          rightText=""
        />
      )}
      {isBannerVisible && !shouldShowBannerAssistant && (
        <Banner
          bannerText={
            "Enjoying the free trial? Upgrade to a premium plan to complete your device's CE mark or FDA submission."
          }
          buttonText={"Upgrade plan"}
          buttonLink={fillRouteWithSlugs(ROUTES.SUBSCRIPTION, {
            [ROUTE_SLUGS.ORG_ID]: orgId,
          })}
        />
      )}

      <LeftDrawer hasBanner={isBannerVisible || shouldShowBannerAssistant} />

      <div
        className={`flex flex-1 flex-col overflow-y-auto p-5 ${
          hasBanner || shouldShowBannerAssistant
            ? "mt-[var(--banner-height)]"
            : ""
        }`}
      >
        <Sentry.ErrorBoundary fallback={(props) => <Fallback {...props} />}>
          {props.children || <Outlet />}
        </Sentry.ErrorBoundary>
      </div>

      {onboardingIsOpen && !!user && !!org && (
        <Dialog
          className="rounded"
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiPaper-root": {
              maxWidth: "100%",
            },
          }}
        >
          <Welcome user={user} closeModal={closeWelcomeModal} />
        </Dialog>
      )}
    </div>
  );
}
